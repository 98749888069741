/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v8 {
  display: block;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-bg);
  text-decoration: none;
  transition: 0.2s;

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--shadow-md);

    .card-v8__title {
      background-size: 100% 100%;
    }
  }
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(
    transparent 50%,
    alpha(var(--color-primary), 0.2) 50%
  );
  background-size: 0% 100%;
}

/* -------------------------------- 

File#: _1_card-v3
Title: Card v3
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */

.card-v3 {
  background-color: var(--color-contrast-lower);
}

.card-v3__link {
  text-decoration: none;
  display: block; // fallback
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;
}

.card-v3__img {
  img {
    display: block;
    width: 100%;
  }
}

.card-v3__content {
  padding: var(--space-sm);
}

.card-v3__label {
  font-size: var(--text-sm);
  margin-bottom: var(--space-xs);
  color: var(--color-contrast-medium);
}

.card-v3__footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  margin-top: auto;

  span {
    // text
    display: inline-block;
    font-size: var(--text-sm);
    will-change: transform;
    transform: translateY(20%);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
  }

  .icon {
    display: block;
    color: inherit;
  }

  &::before {
    // animated bottom line
    content: "";
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left center;
    transform: scaleX(0);
    transition: transform 0.3s var(--ease-in-out);
  }
}

.card-v3:hover {
  .card-v3__footer {
    span {
      transform: translateY(0);
      opacity: 1;
    }

    &::before {
      transform: scaleX(1);
    }
  }
}

.grid__card {
  position: relative;

  &.grid__card--left {
    margin-left: 100px;
    @media screen and (max-width: 768px) {
      margin-left: var(--offset);
    }
  }

  figure, h3 {
    position: relative;
    z-index: 2;
  }

  .img__container {
    position: absolute;
    width: 514px;
    height: 314px;
    left: -60px;
    top: -100px;
    background-color: alpha(var(--color-primary), 0.1);

    @media screen and (max-width: 768px) {
      left: 0;
      right: 0;
      width: 100%;
      height: 240px;
      top: 0;
    }
  }
}
