*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

.btn, .form-control, .link, .reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

[data-theme] {
  background-color: var(--color-bg, white);
  color: var(--color-contrast-high, #313135);
}

:root {
  --space-unit:  1em;
}

:root, * {
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs:  calc(0.25 * var(--space-unit));
  --space-xxs:   calc(0.375 * var(--space-unit));
  --space-xs:    calc(0.5 * var(--space-unit));
  --space-sm:    calc(0.75 * var(--space-unit));
  --space-md:    calc(1.25 * var(--space-unit));
  --space-lg:    calc(2 * var(--space-unit));
  --space-xl:    calc(3.25 * var(--space-unit));
  --space-xxl:   calc(5.25 * var(--space-unit));
  --space-xxxl:  calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

.container {
  width: calc(100% - 2*var(--component-padding));
  margin-left: auto;
  margin-right: auto;
}

.grid {
  --gap: 0px;
  --offset: var(--gap);
  display: flex;
  flex-wrap: wrap;
}

.grid > * {
  flex-basis: 100%;
  max-width: 100%;
  min-width: 0;
}

@supports (--css: variables) {
  .grid > *[class*="offset-"] {
    margin-left: var(--offset);
  }
}

[class*="gap-xxxxs"], [class*="gap-xxxs"], [class*="gap-xxs"], [class*="gap-xs"], [class*="gap-sm"], [class*="gap-md"], [class*="gap-lg"], [class*="gap-xl"], [class*="gap-xxl"], [class*="gap-xxxl"], [class*="gap-xxxxl"], [class*="grid-gap-"], [class*="flex-gap-"] {
  margin-bottom: -0.75em;
  margin-left: -0.75em;
}

[class*="gap-xxxxs"] > *, [class*="gap-xxxs"] > *, [class*="gap-xxs"] > *, [class*="gap-xs"] > *, [class*="gap-sm"] > *, [class*="gap-md"] > *, [class*="gap-lg"] > *, [class*="gap-xl"] > *, [class*="gap-xxl"] > *, [class*="gap-xxxl"] > *, [class*="gap-xxxxl"] > *, [class*="grid-gap-"] > *, [class*="flex-gap-"] > * {
  margin-bottom: 0.75em;
  margin-left: 0.75em;
}

@supports (--css: variables) {
  [class*="gap-xxxxs"], [class*="gap-xxxs"], [class*="gap-xxs"], [class*="gap-xs"], [class*="gap-sm"], [class*="gap-md"], [class*="gap-lg"], [class*="gap-xl"], [class*="gap-xxl"], [class*="gap-xxxl"], [class*="gap-xxxxl"], [class*="grid-gap-"], [class*="flex-gap-"] {
    --gap: 0px;
    --offset: var(--gap);
    margin-bottom: calc(-1 * var(--gap));
    margin-left: calc(-1 * var(--gap));
  }
  [class*="gap-xxxxs"] > *, [class*="gap-xxxs"] > *, [class*="gap-xxs"] > *, [class*="gap-xs"] > *, [class*="gap-sm"] > *, [class*="gap-md"] > *, [class*="gap-lg"] > *, [class*="gap-xl"] > *, [class*="gap-xxl"] > *, [class*="gap-xxxl"] > *, [class*="gap-xxxxl"] > *, [class*="grid-gap-"] > *, [class*="flex-gap-"] > * {
    margin-bottom: var(--gap);
    margin-left: var(--offset);
  }
  .gap-xxxxs, .grid-gap-xxxxs, .flex-gap-xxxxs {
    --gap: var(--space-xxxxs);
  }
  .gap-xxxs, .grid-gap-xxxs, .flex-gap-xxxs {
    --gap: var(--space-xxxs);
  }
  .gap-xxs, .grid-gap-xxs, .flex-gap-xxs {
    --gap: var(--space-xxs);
  }
  .gap-xs, .grid-gap-xs, .flex-gap-xs {
    --gap: var(--space-xs);
  }
  .gap-sm, .grid-gap-sm, .flex-gap-sm {
    --gap: var(--space-sm);
  }
  .gap-md, .grid-gap-md, .flex-gap-md {
    --gap: var(--space-md);
  }
  .gap-lg, .grid-gap-lg, .flex-gap-lg {
    --gap: var(--space-lg);
  }
  .gap-xl, .grid-gap-xl, .flex-gap-xl {
    --gap: var(--space-xl);
  }
  .gap-xxl, .grid-gap-xxl, .flex-gap-xxl {
    --gap: var(--space-xxl);
  }
  .gap-xxxl, .grid-gap-xxxl, .flex-gap-xxxl {
    --gap: var(--space-xxxl);
  }
  .gap-xxxxl, .grid-gap-xxxxl, .flex-gap-xxxxl {
    --gap: var(--space-xxxxl);
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
}

.col-2 {
  flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
}

.col-3 {
  flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
}

.col-4 {
  flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
}

.col-5 {
  flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
}

.col-6 {
  flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
}

.col-7 {
  flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
}

.col-8 {
  flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
}

.col-9 {
  flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
}

.col-10 {
  flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
}

.col-11 {
  flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
}

.col-12 {
  flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
}

.col-content {
  flex-grow: 0;
  flex-basis: initial;
  max-width: initial;
}

.offset-1 {
  --offset: calc(8.33% + var(--gap, 0.75em));
}

.offset-2 {
  --offset: calc(16.66% + var(--gap, 0.75em));
}

.offset-3 {
  --offset: calc(25% + var(--gap, 0.75em));
}

.offset-4 {
  --offset: calc(33.33% + var(--gap, 0.75em));
}

.offset-5 {
  --offset: calc(41.66% + var(--gap, 0.75em));
}

.offset-6 {
  --offset: calc(50% + var(--gap, 0.75em));
}

.offset-7 {
  --offset: calc(58.33% + var(--gap, 0.75em));
}

.offset-8 {
  --offset: calc(66.66% + var(--gap, 0.75em));
}

.offset-9 {
  --offset: calc(75% + var(--gap, 0.75em));
}

.offset-10 {
  --offset: calc(83.33% + var(--gap, 0.75em));
}

.offset-11 {
  --offset: calc(91.66% + var(--gap, 0.75em));
}

@media (min-width: 32rem) {
  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xs {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@xs {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@xs {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@xs {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@xs {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@xs {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@xs {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@xs {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@xs {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@xs {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@xs {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@xs {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@xs {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@xs {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@xs {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@xs {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@xs {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@xs {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@xs {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@xs {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@xs {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@xs {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@xs {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@xs {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
  @supports (--css: variables) {
    .gap-xxxxs\@xs {
      --gap: var(--space-xxxxs);
    }
    .gap-xxxs\@xs {
      --gap: var(--space-xxxs);
    }
    .gap-xxs\@xs {
      --gap: var(--space-xxs);
    }
    .gap-xs\@xs {
      --gap: var(--space-xs);
    }
    .gap-sm\@xs {
      --gap: var(--space-sm);
    }
    .gap-md\@xs {
      --gap: var(--space-md);
    }
    .gap-lg\@xs {
      --gap: var(--space-lg);
    }
    .gap-xl\@xs {
      --gap: var(--space-xl);
    }
    .gap-xxl\@xs {
      --gap: var(--space-xxl);
    }
    .gap-xxxl\@xs {
      --gap: var(--space-xxxl);
    }
    .gap-xxxxl\@xs {
      --gap: var(--space-xxxxl);
    }
    .gap-0\@xs {
      --gap: 0px;
    }
  }
}

@media (min-width: 48rem) {
  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@sm {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@sm {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@sm {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@sm {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@sm {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@sm {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@sm {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@sm {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@sm {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@sm {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@sm {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@sm {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@sm {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@sm {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@sm {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@sm {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@sm {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@sm {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@sm {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@sm {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@sm {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@sm {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@sm {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@sm {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
  @supports (--css: variables) {
    .gap-xxxxs\@sm {
      --gap: var(--space-xxxxs);
    }
    .gap-xxxs\@sm {
      --gap: var(--space-xxxs);
    }
    .gap-xxs\@sm {
      --gap: var(--space-xxs);
    }
    .gap-xs\@sm {
      --gap: var(--space-xs);
    }
    .gap-sm\@sm {
      --gap: var(--space-sm);
    }
    .gap-md\@sm {
      --gap: var(--space-md);
    }
    .gap-lg\@sm {
      --gap: var(--space-lg);
    }
    .gap-xl\@sm {
      --gap: var(--space-xl);
    }
    .gap-xxl\@sm {
      --gap: var(--space-xxl);
    }
    .gap-xxxl\@sm {
      --gap: var(--space-xxxl);
    }
    .gap-xxxxl\@sm {
      --gap: var(--space-xxxxl);
    }
    .gap-0\@sm {
      --gap: 0px;
    }
  }
}

@media (min-width: 64rem) {
  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@md {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@md {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@md {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@md {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@md {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@md {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@md {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@md {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@md {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@md {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@md {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@md {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@md {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@md {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@md {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@md {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@md {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@md {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@md {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@md {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@md {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@md {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@md {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@md {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
  @supports (--css: variables) {
    .gap-xxxxs\@md {
      --gap: var(--space-xxxxs);
    }
    .gap-xxxs\@md {
      --gap: var(--space-xxxs);
    }
    .gap-xxs\@md {
      --gap: var(--space-xxs);
    }
    .gap-xs\@md {
      --gap: var(--space-xs);
    }
    .gap-sm\@md {
      --gap: var(--space-sm);
    }
    .gap-md\@md {
      --gap: var(--space-md);
    }
    .gap-lg\@md {
      --gap: var(--space-lg);
    }
    .gap-xl\@md {
      --gap: var(--space-xl);
    }
    .gap-xxl\@md {
      --gap: var(--space-xxl);
    }
    .gap-xxxl\@md {
      --gap: var(--space-xxxl);
    }
    .gap-xxxxl\@md {
      --gap: var(--space-xxxxl);
    }
    .gap-0\@md {
      --gap: 0px;
    }
  }
}

@media (min-width: 80rem) {
  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@lg {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@lg {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@lg {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@lg {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@lg {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@lg {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@lg {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@lg {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@lg {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@lg {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@lg {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@lg {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@lg {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@lg {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@lg {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@lg {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@lg {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@lg {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@lg {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@lg {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@lg {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@lg {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@lg {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@lg {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
  @supports (--css: variables) {
    .gap-xxxxs\@lg {
      --gap: var(--space-xxxxs);
    }
    .gap-xxxs\@lg {
      --gap: var(--space-xxxs);
    }
    .gap-xxs\@lg {
      --gap: var(--space-xxs);
    }
    .gap-xs\@lg {
      --gap: var(--space-xs);
    }
    .gap-sm\@lg {
      --gap: var(--space-sm);
    }
    .gap-md\@lg {
      --gap: var(--space-md);
    }
    .gap-lg\@lg {
      --gap: var(--space-lg);
    }
    .gap-xl\@lg {
      --gap: var(--space-xl);
    }
    .gap-xxl\@lg {
      --gap: var(--space-xxl);
    }
    .gap-xxxl\@lg {
      --gap: var(--space-xxxl);
    }
    .gap-xxxxl\@lg {
      --gap: var(--space-xxxxl);
    }
    .gap-0\@lg {
      --gap: 0px;
    }
  }
}

@media (min-width: 90rem) {
  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xl {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-2\@xl {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-3\@xl {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }
  .col-4\@xl {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-5\@xl {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-6\@xl {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }
  .col-7\@xl {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-8\@xl {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-9\@xl {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }
  .col-10\@xl {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }
  .col-11\@xl {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }
  .col-12\@xl {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }
  .col-content\@xl {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }
  .offset-1\@xl {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }
  .offset-2\@xl {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }
  .offset-3\@xl {
    --offset: calc(25% + var(--gap, 0.75em));
  }
  .offset-4\@xl {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }
  .offset-5\@xl {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }
  .offset-6\@xl {
    --offset: calc(50% + var(--gap, 0.75em));
  }
  .offset-7\@xl {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }
  .offset-8\@xl {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }
  .offset-9\@xl {
    --offset: calc(75% + var(--gap, 0.75em));
  }
  .offset-10\@xl {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }
  .offset-11\@xl {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }
  @supports (--css: variables) {
    .gap-xxxxs\@xl {
      --gap: var(--space-xxxxs);
    }
    .gap-xxxs\@xl {
      --gap: var(--space-xxxs);
    }
    .gap-xxs\@xl {
      --gap: var(--space-xxs);
    }
    .gap-xs\@xl {
      --gap: var(--space-xs);
    }
    .gap-sm\@xl {
      --gap: var(--space-sm);
    }
    .gap-md\@xl {
      --gap: var(--space-md);
    }
    .gap-lg\@xl {
      --gap: var(--space-lg);
    }
    .gap-xl\@xl {
      --gap: var(--space-xl);
    }
    .gap-xxl\@xl {
      --gap: var(--space-xxl);
    }
    .gap-xxxl\@xl {
      --gap: var(--space-xxxl);
    }
    .gap-xxxxl\@xl {
      --gap: var(--space-xxxxl);
    }
    .gap-0\@xl {
      --gap: 0px;
    }
  }
}

:root {
  --radius-sm: calc(var(--radius, 0.25em)/2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em)*2);
  --shadow-xs:  0 0.1px 0.3px rgba(0, 0, 0, 0.06),
                  0 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-sm:  0 0.3px 0.4px rgba(0, 0, 0, 0.025),
                  0 0.9px 1.5px rgba(0, 0, 0, 0.05), 
                  0 3.5px 6px rgba(0, 0, 0, 0.1);
  --shadow-md:  0 0.9px 1.5px rgba(0, 0, 0, 0.03), 
                  0 3.1px 5.5px rgba(0, 0, 0, 0.08), 
                  0 14px 25px rgba(0, 0, 0, 0.12);
  --shadow-lg:  0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014), 
                  0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038), 
                  0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085), 
                  0 30px 42px -1px rgba(0, 0, 0, 0.15);
  --shadow-xl:  0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012), 
                  0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035), 
                  0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07), 
                  0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117), 
                  0 46px 60px -6px rgba(0, 0, 0, 0.2);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1em);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-contrast-high, #313135);
}

h1, h2, h3, h4 {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
}

h1 {
  font-size: var(--text-xxl, 2.074em);
}

h2 {
  font-size: var(--text-xl, 1.728em);
}

h3 {
  font-size: var(--text-lg, 1.44em);
}

h4 {
  font-size: var(--text-md, 1.2em);
}

small {
  font-size: var(--text-sm, 0.833em);
}

a, .link {
  color: var(--color-primary, #2a6df4);
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1, .text-component h2, .text-component h3, .text-component h4 {
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 0.25 * var(--text-vspace-multiplier, 1));
}

.text-component h2, .text-component h3, .text-component h4 {
  margin-top: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component p, .text-component blockquote, .text-component ul li, .text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}

.text-component ul, .text-component ol, .text-component p, .text-component blockquote, .text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component ul, .text-component ol {
  list-style-position: inside;
}

.text-component ul {
  list-style-type: disc;
}

.text-component ol {
  list-style-type: decimal;
}

.text-component img {
  display: block;
  margin: 0 auto;
}

.text-component figcaption {
  text-align: center;
  margin-top: calc(var(--space-unit) * 0.5);
}

.text-component em {
  font-style: italic;
}

.text-component hr {
  margin-top: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}

.text-component > *:first-child {
  margin-top: 0;
}

.text-component > *:last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }
  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }
  .text-component__block--outset img {
    width: 100%;
  }
  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }
  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }
  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}

:root {
  --icon-xxxs: 8px;
  --icon-xxs:  12px;
  --icon-xs:   16px;
  --icon-sm:   24px;
  --icon-md:   32px;
  --icon-lg:   48px;
  --icon-xl:   64px;
  --icon-xxl:  96px;
  --icon-xxxl: 128px;
}

.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--xxxs {
  width: var(--icon-xxxs);
  height: var(--icon-xxxs);
}

.icon--xxs {
  width: var(--icon-xxs);
  height: var(--icon-xxs);
}

.icon--xs {
  width: var(--icon-xs);
  height: var(--icon-xs);
}

.icon--sm {
  width: var(--icon-sm);
  height: var(--icon-sm);
}

.icon--md {
  width: var(--icon-md);
  height: var(--icon-md);
}

.icon--lg {
  width: var(--icon-lg);
  height: var(--icon-lg);
}

.icon--xl {
  width: var(--icon-xl);
  height: var(--icon-xl);
}

.icon--xxl {
  width: var(--icon-xxl);
  height: var(--icon-xxl);
}

.icon--xxxl {
  width: var(--icon-xxxl);
  height: var(--icon-xxxl);
}

.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
}

.btn--sm {
  font-size: var(--btn-font-size-sm, 0.8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, 0.5em);
}

.form-control {
  font-size: var(--form-control-font-size, 1em);
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: var(--form-control-radius, 0.25em);
}

.form-legend {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
  font-size: var(--text-md, 1.2em);
  margin-bottom: var(--space-xxs);
}

.form-label {
  display: inline-block;
}

.form__msg-error, .form-error-msg {
  color: var(--color-error, #e02447);
  font-size: var(--text-sm, 0.833em);
  margin-top: var(--space-xxs);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.form__msg-error--is-visible, .form-error-msg--is-visible {
  position: relative;
  clip: auto;
}

.radio-list > *, .checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
}

.radio-list label, .checkbox-list label {
  line-height: var(--body-line-height);
}

.radio-list input, .checkbox-list input {
  margin-right: var(--space-xxxs);
  flex-shrink: 0;
}

:root {
  --zindex-header: 3;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}

:root {
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden {
  display: none !important;
}

html:not(.js) .no-js\:is-hidden {
  display: none !important;
}

@media print {
  .print\:is-hidden {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.space-unit-rem {
  --space-unit: 1rem;
}

.space-unit-em {
  --space-unit: 1em;
}

.space-unit-px {
  --space-unit: 16px;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-x-xxxxs {
  margin-left: var(--space-xxxxs);
  margin-right: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-left: var(--space-xxxs);
  margin-right: var(--space-xxxs);
}

.margin-x-xxs {
  margin-left: var(--space-xxs);
  margin-right: var(--space-xxs);
}

.margin-x-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-x-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-x-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-x-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-lg);
}

.margin-x-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.margin-x-xxl {
  margin-left: var(--space-xxl);
  margin-right: var(--space-xxl);
}

.margin-x-xxxl {
  margin-left: var(--space-xxxl);
  margin-right: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-left: var(--space-xxxxl);
  margin-right: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-0 {
  padding: 0;
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-left: var(--space-xxxs);
  padding-right: var(--space-xxxs);
}

.padding-x-xxs {
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
}

.padding-x-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-x-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-x-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-x-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
}

.padding-x-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.padding-x-xxl {
  padding-left: var(--space-xxl);
  padding-right: var(--space-xxl);
}

.padding-x-xxxl {
  padding-left: var(--space-xxxl);
  padding-right: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-left: var(--space-xxxxl);
  padding-right: var(--space-xxxxl);
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-component {
  padding-left: var(--component-padding);
  padding-right: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
  min-width: 0;
}

.text-xs {
  font-size: var(--text-xs, 0.694em);
}

.text-sm {
  font-size: var(--text-sm, 0.833em);
}

.text-base {
  font-size: var(--text-unit, 1em);
}

.text-md {
  font-size: var(--text-md, 1.2em);
}

.text-lg {
  font-size: var(--text-lg, 1.44em);
}

.text-xl {
  font-size: var(--text-xl, 1.728em);
}

.text-xxl {
  font-size: var(--text-xxl, 2.074em);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.488em);
}

.text-xxxxl {
  font-size: var(--text-xxxxl, 2.985em);
}

.text-unit-rem, .text-unit-em, .text-unit-px {
  font-size: var(--text-unit);
}

.text-unit-rem {
  --text-unit: 1rem;
}

.text-unit-em {
  --text-unit: 1em;
}

.text-unit-px {
  --text-unit: 16px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold, .text-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.text-shadow-xs {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.text-shadow-sm {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.text-shadow-md {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.text-shadow-lg {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.2);
}

.text-shadow-xl {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.2), 0 6px 24px rgba(0, 0, 0, 0.25);
}

.text-shadow-none {
  text-shadow: none;
}

.v-space-xxs {
  --text-vspace-multiplier: 0.25 !important;
}

.v-space-xs {
  --text-vspace-multiplier: 0.5 !important;
}

.v-space-sm {
  --text-vspace-multiplier: 0.75 !important;
}

.v-space-md {
  --text-vspace-multiplier: 1.25 !important;
}

.v-space-lg {
  --text-vspace-multiplier: 1.5 !important;
}

.v-space-xl {
  --text-vspace-multiplier: 1.75 !important;
}

.v-space-xxl {
  --text-vspace-multiplier: 2 !important;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1.1;
}

.line-height-xs:not(.text-component) {
  line-height: 1.1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}

.line-height-sm:not(.text-component) {
  line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}

.line-height-md:not(.text-component) {
  line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}

.line-height-lg:not(.text-component) {
  line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}

.line-height-xl:not(.text-component) {
  line-height: 1.72;
}

.line-height-body {
  line-height: var(--body-line-height);
}

.line-height-heading {
  line-height: var(--heading-line-height);
}

.line-height-normal {
  line-height: normal !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.ws-nowrap, .text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

[class*="color-"] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-o, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-o, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-o, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-o, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-o, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-o, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-o, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-o, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-o, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-o, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-o, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-o, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-o, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-o, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

@supports (--css: variables) {
  .color-opacity-0 {
    --color-o: 0;
  }
  .color-opacity-10\% {
    --color-o: 0.1;
  }
  .color-opacity-20\% {
    --color-o: 0.2;
  }
  .color-opacity-30\% {
    --color-o: 0.3;
  }
  .color-opacity-40\% {
    --color-o: 0.4;
  }
  .color-opacity-50\% {
    --color-o: 0.5;
  }
  .color-opacity-60\% {
    --color-o: 0.6;
  }
  .color-opacity-70\% {
    --color-o: 0.7;
  }
  .color-opacity-80\% {
    --color-o: 0.8;
  }
  .color-opacity-90\% {
    --color-o: 0.9;
  }
}

.width-xxxxs {
  width: var(--size-xxxxs, 0.25rem);
}

.width-xxxs {
  width: var(--size-xxxs, 0.5rem);
}

.width-xxs {
  width: var(--size-xxs, 0.75rem);
}

.width-xs {
  width: var(--size-xs, 1rem);
}

.width-sm {
  width: var(--size-sm, 1.5rem);
}

.width-md {
  width: var(--size-md, 2rem);
}

.width-lg {
  width: var(--size-lg, 3rem);
}

.width-xl {
  width: var(--size-xl, 4rem);
}

.width-xxl {
  width: var(--size-xxl, 6rem);
}

.width-xxxl {
  width: var(--size-xxxl, 8rem);
}

.width-xxxxl {
  width: var(--size-xxxxl, 16rem);
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: calc(100% / 3);
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: calc(100% / 1.5);
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.height-xxxxs {
  height: var(--size-xxxxs, 0.25rem);
}

.height-xxxs {
  height: var(--size-xxxs, 0.5rem);
}

.height-xxs {
  height: var(--size-xxs, 0.75rem);
}

.height-xs {
  height: var(--size-xs, 1rem);
}

.height-sm {
  height: var(--size-sm, 1.5rem);
}

.height-md {
  height: var(--size-md, 2rem);
}

.height-lg {
  height: var(--size-lg, 3rem);
}

.height-xl {
  height: var(--size-xl, 4rem);
}

.height-xxl {
  height: var(--size-xxl, 6rem);
}

.height-xxxl {
  height: var(--size-xxxl, 8rem);
}

.height-xxxxl {
  height: var(--size-xxxxl, 16rem);
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: calc(100% / 3);
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: calc(100% / 1.5);
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: calc(100% / 3);
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: calc(100% / 1.5);
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

:root {
  --max-width-xxxxs: 20rem;
  --max-width-xxxs:  26rem;
  --max-width-xxs:   32rem;
  --max-width-xs:    38rem;
  --max-width-sm:    48rem;
  --max-width-md:    64rem;
  --max-width-lg:    80rem;
  --max-width-xl:    90rem;
  --max-width-xxl:   100rem;
  --max-width-xxxl:  120rem;
  --max-width-xxxxl: 150rem;
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

.max-width-100\% {
  max-width: 100%;
}

[class*="max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 48rem;
  }
}

@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 64rem;
  }
}

@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 80rem;
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}

.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.z-index-header {
  z-index: var(--zindex-header);
}

.z-index-popover {
  z-index: var(--zindex-popover);
}

.z-index-fixed-element {
  z-index: var(--zindex-fixed-element);
}

.z-index-overlay {
  z-index: var(--zindex-overlay);
}

.zindex-1, .z-index-1 {
  z-index: 1;
}

.zindex-2, .z-index-2 {
  z-index: 2;
}

.zindex-3, .z-index-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-90\% {
  opacity: 0.9;
}

.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.media-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-wrapper--4\:3 {
  padding-bottom: calc((3 / 4) * 100%);
}

.media-wrapper--1\:1 {
  padding-bottom: calc((1 / 1) * 100%);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

[class*="border-"] {
  --border-o: 1;
}

.border {
  border: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-top {
  border-top: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-bottom {
  border-bottom: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-left {
  border-left: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-right {
  border-right: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-o, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-o, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-o, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-o, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-o, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-o, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-o, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-o, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-o, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-o, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-o, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-o, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-o, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-o, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

@supports (--css: variables) {
  .border-opacity-0 {
    --border-o: 0;
  }
  .border-opacity-10\% {
    --border-o: 0.1;
  }
  .border-opacity-20\% {
    --border-o: 0.2;
  }
  .border-opacity-30\% {
    --border-o: 0.3;
  }
  .border-opacity-40\% {
    --border-o: 0.4;
  }
  .border-opacity-50\% {
    --border-o: 0.5;
  }
  .border-opacity-60\% {
    --border-o: 0.6;
  }
  .border-opacity-70\% {
    --border-o: 0.7;
  }
  .border-opacity-80\% {
    --border-o: 0.8;
  }
  .border-opacity-90\% {
    --border-o: 0.9;
  }
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-0 {
  border-radius: 0;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.shadow-none {
  box-shadow: none;
}

.bg, [class*="bg-"] {
  --bg-o: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-o, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-o, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-o, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-o, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-o, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-o, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-o, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-o, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-o, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-o, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-o, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-o, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-o, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-o, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-o, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-o, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-o, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-o, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-o, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o, 1));
}

@supports (--css: variables) {
  .bg-opacity-0 {
    --bg-o: 0;
  }
  .bg-opacity-10\% {
    --bg-o: 0.1;
  }
  .bg-opacity-20\% {
    --bg-o: 0.2;
  }
  .bg-opacity-30\% {
    --bg-o: 0.3;
  }
  .bg-opacity-40\% {
    --bg-o: 0.4;
  }
  .bg-opacity-50\% {
    --bg-o: 0.5;
  }
  .bg-opacity-60\% {
    --bg-o: 0.6;
  }
  .bg-opacity-70\% {
    --bg-o: 0.7;
  }
  .bg-opacity-80\% {
    --bg-o: 0.8;
  }
  .bg-opacity-90\% {
    --bg-o: 0.9;
  }
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.flip {
  transform: scale(-1);
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

@media (min-width: 32rem) {
  .flex\@xs {
    display: flex;
  }
  .inline-flex\@xs {
    display: inline-flex;
  }
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xs {
    flex-grow: 1;
  }
  .flex-grow-0\@xs {
    flex-grow: 0;
  }
  .flex-shrink\@xs {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }
  .flex-basis-0\@xs {
    flex-basis: 0;
  }
  .justify-start\@xs {
    justify-content: flex-start;
  }
  .justify-end\@xs {
    justify-content: flex-end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: flex-start;
  }
  .items-end\@xs {
    align-items: flex-end;
  }
  .items-baseline\@xs {
    align-items: baseline;
  }
  .order-1\@xs {
    order: 1;
  }
  .order-2\@xs {
    order: 2;
  }
  .order-3\@xs {
    order: 3;
  }
  .block\@xs {
    display: block;
  }
  .inline-block\@xs {
    display: inline-block;
  }
  .inline\@xs {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@xs {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@xs {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@xs {
      margin: var(--space-xxs);
    }
    .margin-xs\@xs {
      margin: var(--space-xs);
    }
    .margin-sm\@xs {
      margin: var(--space-sm);
    }
    .margin-md\@xs {
      margin: var(--space-md);
    }
    .margin-lg\@xs {
      margin: var(--space-lg);
    }
    .margin-xl\@xs {
      margin: var(--space-xl);
    }
    .margin-xxl\@xs {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@xs {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@xs {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@xs {
      margin: auto;
    }
    .margin-0\@xs {
      margin: 0;
    }
    .margin-top-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@xs {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@xs {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@xs {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@xs {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@xs {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@xs {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@xs {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@xs {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@xs {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@xs {
      margin-top: auto;
    }
    .margin-top-0\@xs {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@xs {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@xs {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@xs {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@xs {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@xs {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@xs {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@xs {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@xs {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@xs {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@xs {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@xs {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@xs {
      margin-bottom: auto;
    }
    .margin-bottom-0\@xs {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@xs {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@xs {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@xs {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@xs {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@xs {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@xs {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@xs {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@xs {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@xs {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@xs {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@xs {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@xs {
      margin-right: auto;
    }
    .margin-right-0\@xs {
      margin-right: 0;
    }
    .margin-left-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@xs {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@xs {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@xs {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@xs {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@xs {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@xs {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@xs {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@xs {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@xs {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@xs {
      margin-left: auto;
    }
    .margin-left-0\@xs {
      margin-left: 0;
    }
    .margin-x-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@xs {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@xs {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@xs {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@xs {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@xs {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@xs {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@xs {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@xs {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@xs {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@xs {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@xs {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@xs {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@xs {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@xs {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@xs {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@xs {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@xs {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@xs {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@xs {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@xs {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@xs {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@xs {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@xs {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@xs {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@xs {
      padding: var(--space-xxs);
    }
    .padding-xs\@xs {
      padding: var(--space-xs);
    }
    .padding-sm\@xs {
      padding: var(--space-sm);
    }
    .padding-md\@xs {
      padding: var(--space-md);
    }
    .padding-lg\@xs {
      padding: var(--space-lg);
    }
    .padding-xl\@xs {
      padding: var(--space-xl);
    }
    .padding-xxl\@xs {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@xs {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@xs {
      padding: var(--space-xxxxl);
    }
    .padding-0\@xs {
      padding: 0;
    }
    .padding-component\@xs {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@xs {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@xs {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@xs {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@xs {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@xs {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@xs {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@xs {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@xs {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@xs {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@xs {
      padding-top: 0;
    }
    .padding-top-component\@xs {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@xs {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@xs {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@xs {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@xs {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@xs {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@xs {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@xs {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@xs {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@xs {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@xs {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@xs {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@xs {
      padding-bottom: 0;
    }
    .padding-bottom-component\@xs {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@xs {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@xs {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@xs {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@xs {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@xs {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@xs {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@xs {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@xs {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@xs {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@xs {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@xs {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@xs {
      padding-right: 0;
    }
    .padding-right-component\@xs {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@xs {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@xs {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@xs {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@xs {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@xs {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@xs {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@xs {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@xs {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@xs {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@xs {
      padding-left: 0;
    }
    .padding-left-component\@xs {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@xs {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@xs {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@xs {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@xs {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@xs {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@xs {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@xs {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@xs {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@xs {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@xs {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@xs {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@xs {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@xs {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@xs {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@xs {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@xs {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@xs {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@xs {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@xs {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@xs {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@xs {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@xs {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
  .text-justify\@xs {
    text-align: justify;
  }
  @supports (--css: variables) {
    .text-xs\@xs {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@xs {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@xs {
      font-size: var(--text-unit, 1em);
    }
    .text-md\@xs {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@xs {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@xs {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@xs {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@xs {
      font-size: var(--text-xxxl, 2.488em);
    }
    .text-xxxxl\@xs {
      font-size: var(--text-xxxxl, 2.985em);
    }
  }
  .hide\@xs {
    display: none !important;
  }
}

@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
  .has-padding\@xs {
    padding: 0 !important;
  }
  .display\@xs {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }
  .inline-flex\@sm {
    display: inline-flex;
  }
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@sm {
    flex-grow: 1;
  }
  .flex-grow-0\@sm {
    flex-grow: 0;
  }
  .flex-shrink\@sm {
    flex-shrink: 1;
  }
  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }
  .flex-basis-0\@sm {
    flex-basis: 0;
  }
  .justify-start\@sm {
    justify-content: flex-start;
  }
  .justify-end\@sm {
    justify-content: flex-end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: flex-start;
  }
  .items-end\@sm {
    align-items: flex-end;
  }
  .items-baseline\@sm {
    align-items: baseline;
  }
  .order-1\@sm {
    order: 1;
  }
  .order-2\@sm {
    order: 2;
  }
  .order-3\@sm {
    order: 3;
  }
  .block\@sm {
    display: block;
  }
  .inline-block\@sm {
    display: inline-block;
  }
  .inline\@sm {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@sm {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@sm {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@sm {
      margin: var(--space-xxs);
    }
    .margin-xs\@sm {
      margin: var(--space-xs);
    }
    .margin-sm\@sm {
      margin: var(--space-sm);
    }
    .margin-md\@sm {
      margin: var(--space-md);
    }
    .margin-lg\@sm {
      margin: var(--space-lg);
    }
    .margin-xl\@sm {
      margin: var(--space-xl);
    }
    .margin-xxl\@sm {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@sm {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@sm {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@sm {
      margin: auto;
    }
    .margin-0\@sm {
      margin: 0;
    }
    .margin-top-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@sm {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@sm {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@sm {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@sm {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@sm {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@sm {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@sm {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@sm {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@sm {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@sm {
      margin-top: auto;
    }
    .margin-top-0\@sm {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@sm {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@sm {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@sm {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@sm {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@sm {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@sm {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@sm {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@sm {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@sm {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@sm {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@sm {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@sm {
      margin-bottom: auto;
    }
    .margin-bottom-0\@sm {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@sm {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@sm {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@sm {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@sm {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@sm {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@sm {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@sm {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@sm {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@sm {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@sm {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@sm {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@sm {
      margin-right: auto;
    }
    .margin-right-0\@sm {
      margin-right: 0;
    }
    .margin-left-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@sm {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@sm {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@sm {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@sm {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@sm {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@sm {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@sm {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@sm {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@sm {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@sm {
      margin-left: auto;
    }
    .margin-left-0\@sm {
      margin-left: 0;
    }
    .margin-x-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@sm {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@sm {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@sm {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@sm {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@sm {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@sm {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@sm {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@sm {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@sm {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@sm {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@sm {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@sm {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@sm {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@sm {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@sm {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@sm {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@sm {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@sm {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@sm {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@sm {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@sm {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@sm {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@sm {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@sm {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@sm {
      padding: var(--space-xxs);
    }
    .padding-xs\@sm {
      padding: var(--space-xs);
    }
    .padding-sm\@sm {
      padding: var(--space-sm);
    }
    .padding-md\@sm {
      padding: var(--space-md);
    }
    .padding-lg\@sm {
      padding: var(--space-lg);
    }
    .padding-xl\@sm {
      padding: var(--space-xl);
    }
    .padding-xxl\@sm {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@sm {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@sm {
      padding: var(--space-xxxxl);
    }
    .padding-0\@sm {
      padding: 0;
    }
    .padding-component\@sm {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@sm {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@sm {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@sm {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@sm {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@sm {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@sm {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@sm {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@sm {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@sm {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@sm {
      padding-top: 0;
    }
    .padding-top-component\@sm {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@sm {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@sm {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@sm {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@sm {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@sm {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@sm {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@sm {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@sm {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@sm {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@sm {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@sm {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@sm {
      padding-bottom: 0;
    }
    .padding-bottom-component\@sm {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@sm {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@sm {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@sm {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@sm {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@sm {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@sm {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@sm {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@sm {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@sm {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@sm {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@sm {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@sm {
      padding-right: 0;
    }
    .padding-right-component\@sm {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@sm {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@sm {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@sm {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@sm {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@sm {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@sm {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@sm {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@sm {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@sm {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@sm {
      padding-left: 0;
    }
    .padding-left-component\@sm {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@sm {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@sm {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@sm {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@sm {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@sm {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@sm {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@sm {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@sm {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@sm {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@sm {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@sm {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@sm {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@sm {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@sm {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@sm {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@sm {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@sm {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@sm {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@sm {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@sm {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@sm {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@sm {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
  .text-justify\@sm {
    text-align: justify;
  }
  @supports (--css: variables) {
    .text-xs\@sm {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@sm {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@sm {
      font-size: var(--text-unit, 1em);
    }
    .text-md\@sm {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@sm {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@sm {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@sm {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@sm {
      font-size: var(--text-xxxl, 2.488em);
    }
    .text-xxxxl\@sm {
      font-size: var(--text-xxxxl, 2.985em);
    }
  }
  .hide\@sm {
    display: none !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
  .has-padding\@sm {
    padding: 0 !important;
  }
  .display\@sm {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }
  .inline-flex\@md {
    display: inline-flex;
  }
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@md {
    flex-grow: 1;
  }
  .flex-grow-0\@md {
    flex-grow: 0;
  }
  .flex-shrink\@md {
    flex-shrink: 1;
  }
  .flex-shrink-0\@md {
    flex-shrink: 0;
  }
  .flex-basis-0\@md {
    flex-basis: 0;
  }
  .justify-start\@md {
    justify-content: flex-start;
  }
  .justify-end\@md {
    justify-content: flex-end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: flex-start;
  }
  .items-end\@md {
    align-items: flex-end;
  }
  .items-baseline\@md {
    align-items: baseline;
  }
  .order-1\@md {
    order: 1;
  }
  .order-2\@md {
    order: 2;
  }
  .order-3\@md {
    order: 3;
  }
  .block\@md {
    display: block;
  }
  .inline-block\@md {
    display: inline-block;
  }
  .inline\@md {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@md {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@md {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@md {
      margin: var(--space-xxs);
    }
    .margin-xs\@md {
      margin: var(--space-xs);
    }
    .margin-sm\@md {
      margin: var(--space-sm);
    }
    .margin-md\@md {
      margin: var(--space-md);
    }
    .margin-lg\@md {
      margin: var(--space-lg);
    }
    .margin-xl\@md {
      margin: var(--space-xl);
    }
    .margin-xxl\@md {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@md {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@md {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@md {
      margin: auto;
    }
    .margin-0\@md {
      margin: 0;
    }
    .margin-top-xxxxs\@md {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@md {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@md {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@md {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@md {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@md {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@md {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@md {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@md {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@md {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@md {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@md {
      margin-top: auto;
    }
    .margin-top-0\@md {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@md {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@md {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@md {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@md {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@md {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@md {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@md {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@md {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@md {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@md {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@md {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@md {
      margin-bottom: auto;
    }
    .margin-bottom-0\@md {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@md {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@md {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@md {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@md {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@md {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@md {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@md {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@md {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@md {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@md {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@md {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@md {
      margin-right: auto;
    }
    .margin-right-0\@md {
      margin-right: 0;
    }
    .margin-left-xxxxs\@md {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@md {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@md {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@md {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@md {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@md {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@md {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@md {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@md {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@md {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@md {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@md {
      margin-left: auto;
    }
    .margin-left-0\@md {
      margin-left: 0;
    }
    .margin-x-xxxxs\@md {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@md {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@md {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@md {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@md {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@md {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@md {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@md {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@md {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@md {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@md {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@md {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@md {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@md {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@md {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@md {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@md {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@md {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@md {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@md {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@md {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@md {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@md {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@md {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@md {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@md {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@md {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@md {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@md {
      padding: var(--space-xxs);
    }
    .padding-xs\@md {
      padding: var(--space-xs);
    }
    .padding-sm\@md {
      padding: var(--space-sm);
    }
    .padding-md\@md {
      padding: var(--space-md);
    }
    .padding-lg\@md {
      padding: var(--space-lg);
    }
    .padding-xl\@md {
      padding: var(--space-xl);
    }
    .padding-xxl\@md {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@md {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@md {
      padding: var(--space-xxxxl);
    }
    .padding-0\@md {
      padding: 0;
    }
    .padding-component\@md {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@md {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@md {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@md {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@md {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@md {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@md {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@md {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@md {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@md {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@md {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@md {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@md {
      padding-top: 0;
    }
    .padding-top-component\@md {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@md {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@md {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@md {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@md {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@md {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@md {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@md {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@md {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@md {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@md {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@md {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@md {
      padding-bottom: 0;
    }
    .padding-bottom-component\@md {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@md {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@md {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@md {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@md {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@md {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@md {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@md {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@md {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@md {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@md {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@md {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@md {
      padding-right: 0;
    }
    .padding-right-component\@md {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@md {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@md {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@md {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@md {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@md {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@md {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@md {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@md {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@md {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@md {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@md {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@md {
      padding-left: 0;
    }
    .padding-left-component\@md {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@md {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@md {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@md {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@md {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@md {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@md {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@md {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@md {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@md {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@md {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@md {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@md {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@md {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@md {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@md {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@md {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@md {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@md {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@md {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@md {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@md {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@md {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@md {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@md {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@md {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@md {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
  .text-justify\@md {
    text-align: justify;
  }
  @supports (--css: variables) {
    .text-xs\@md {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@md {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@md {
      font-size: var(--text-unit, 1em);
    }
    .text-md\@md {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@md {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@md {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@md {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@md {
      font-size: var(--text-xxxl, 2.488em);
    }
    .text-xxxxl\@md {
      font-size: var(--text-xxxxl, 2.985em);
    }
  }
  .hide\@md {
    display: none !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
  .has-padding\@md {
    padding: 0 !important;
  }
  .display\@md {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }
  .inline-flex\@lg {
    display: inline-flex;
  }
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@lg {
    flex-grow: 1;
  }
  .flex-grow-0\@lg {
    flex-grow: 0;
  }
  .flex-shrink\@lg {
    flex-shrink: 1;
  }
  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }
  .flex-basis-0\@lg {
    flex-basis: 0;
  }
  .justify-start\@lg {
    justify-content: flex-start;
  }
  .justify-end\@lg {
    justify-content: flex-end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: flex-start;
  }
  .items-end\@lg {
    align-items: flex-end;
  }
  .items-baseline\@lg {
    align-items: baseline;
  }
  .order-1\@lg {
    order: 1;
  }
  .order-2\@lg {
    order: 2;
  }
  .order-3\@lg {
    order: 3;
  }
  .block\@lg {
    display: block;
  }
  .inline-block\@lg {
    display: inline-block;
  }
  .inline\@lg {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@lg {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@lg {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@lg {
      margin: var(--space-xxs);
    }
    .margin-xs\@lg {
      margin: var(--space-xs);
    }
    .margin-sm\@lg {
      margin: var(--space-sm);
    }
    .margin-md\@lg {
      margin: var(--space-md);
    }
    .margin-lg\@lg {
      margin: var(--space-lg);
    }
    .margin-xl\@lg {
      margin: var(--space-xl);
    }
    .margin-xxl\@lg {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@lg {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@lg {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@lg {
      margin: auto;
    }
    .margin-0\@lg {
      margin: 0;
    }
    .margin-top-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@lg {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@lg {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@lg {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@lg {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@lg {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@lg {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@lg {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@lg {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@lg {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@lg {
      margin-top: auto;
    }
    .margin-top-0\@lg {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@lg {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@lg {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@lg {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@lg {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@lg {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@lg {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@lg {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@lg {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@lg {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@lg {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@lg {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@lg {
      margin-bottom: auto;
    }
    .margin-bottom-0\@lg {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@lg {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@lg {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@lg {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@lg {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@lg {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@lg {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@lg {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@lg {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@lg {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@lg {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@lg {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@lg {
      margin-right: auto;
    }
    .margin-right-0\@lg {
      margin-right: 0;
    }
    .margin-left-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@lg {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@lg {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@lg {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@lg {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@lg {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@lg {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@lg {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@lg {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@lg {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@lg {
      margin-left: auto;
    }
    .margin-left-0\@lg {
      margin-left: 0;
    }
    .margin-x-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@lg {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@lg {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@lg {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@lg {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@lg {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@lg {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@lg {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@lg {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@lg {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@lg {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@lg {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@lg {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@lg {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@lg {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@lg {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@lg {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@lg {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@lg {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@lg {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@lg {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@lg {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@lg {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@lg {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@lg {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@lg {
      padding: var(--space-xxs);
    }
    .padding-xs\@lg {
      padding: var(--space-xs);
    }
    .padding-sm\@lg {
      padding: var(--space-sm);
    }
    .padding-md\@lg {
      padding: var(--space-md);
    }
    .padding-lg\@lg {
      padding: var(--space-lg);
    }
    .padding-xl\@lg {
      padding: var(--space-xl);
    }
    .padding-xxl\@lg {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@lg {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@lg {
      padding: var(--space-xxxxl);
    }
    .padding-0\@lg {
      padding: 0;
    }
    .padding-component\@lg {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@lg {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@lg {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@lg {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@lg {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@lg {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@lg {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@lg {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@lg {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@lg {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@lg {
      padding-top: 0;
    }
    .padding-top-component\@lg {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@lg {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@lg {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@lg {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@lg {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@lg {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@lg {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@lg {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@lg {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@lg {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@lg {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@lg {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@lg {
      padding-bottom: 0;
    }
    .padding-bottom-component\@lg {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@lg {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@lg {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@lg {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@lg {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@lg {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@lg {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@lg {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@lg {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@lg {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@lg {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@lg {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@lg {
      padding-right: 0;
    }
    .padding-right-component\@lg {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@lg {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@lg {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@lg {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@lg {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@lg {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@lg {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@lg {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@lg {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@lg {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@lg {
      padding-left: 0;
    }
    .padding-left-component\@lg {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@lg {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@lg {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@lg {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@lg {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@lg {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@lg {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@lg {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@lg {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@lg {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@lg {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@lg {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@lg {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@lg {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@lg {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@lg {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@lg {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@lg {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@lg {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@lg {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@lg {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@lg {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@lg {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
  .text-justify\@lg {
    text-align: justify;
  }
  @supports (--css: variables) {
    .text-xs\@lg {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@lg {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@lg {
      font-size: var(--text-unit, 1em);
    }
    .text-md\@lg {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@lg {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@lg {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@lg {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@lg {
      font-size: var(--text-xxxl, 2.488em);
    }
    .text-xxxxl\@lg {
      font-size: var(--text-xxxxl, 2.985em);
    }
  }
  .hide\@lg {
    display: none !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
  .has-padding\@lg {
    padding: 0 !important;
  }
  .display\@lg {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }
  .inline-flex\@xl {
    display: inline-flex;
  }
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xl {
    flex-grow: 1;
  }
  .flex-grow-0\@xl {
    flex-grow: 0;
  }
  .flex-shrink\@xl {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }
  .flex-basis-0\@xl {
    flex-basis: 0;
  }
  .justify-start\@xl {
    justify-content: flex-start;
  }
  .justify-end\@xl {
    justify-content: flex-end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: flex-start;
  }
  .items-end\@xl {
    align-items: flex-end;
  }
  .items-baseline\@xl {
    align-items: baseline;
  }
  .order-1\@xl {
    order: 1;
  }
  .order-2\@xl {
    order: 2;
  }
  .order-3\@xl {
    order: 3;
  }
  .block\@xl {
    display: block;
  }
  .inline-block\@xl {
    display: inline-block;
  }
  .inline\@xl {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@xl {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@xl {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@xl {
      margin: var(--space-xxs);
    }
    .margin-xs\@xl {
      margin: var(--space-xs);
    }
    .margin-sm\@xl {
      margin: var(--space-sm);
    }
    .margin-md\@xl {
      margin: var(--space-md);
    }
    .margin-lg\@xl {
      margin: var(--space-lg);
    }
    .margin-xl\@xl {
      margin: var(--space-xl);
    }
    .margin-xxl\@xl {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@xl {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@xl {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@xl {
      margin: auto;
    }
    .margin-0\@xl {
      margin: 0;
    }
    .margin-top-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@xl {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@xl {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@xl {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@xl {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@xl {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@xl {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@xl {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@xl {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@xl {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@xl {
      margin-top: auto;
    }
    .margin-top-0\@xl {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@xl {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@xl {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@xl {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@xl {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@xl {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@xl {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@xl {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@xl {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@xl {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@xl {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@xl {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@xl {
      margin-bottom: auto;
    }
    .margin-bottom-0\@xl {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@xl {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@xl {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@xl {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@xl {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@xl {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@xl {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@xl {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@xl {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@xl {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@xl {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@xl {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@xl {
      margin-right: auto;
    }
    .margin-right-0\@xl {
      margin-right: 0;
    }
    .margin-left-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@xl {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@xl {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@xl {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@xl {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@xl {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@xl {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@xl {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@xl {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@xl {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@xl {
      margin-left: auto;
    }
    .margin-left-0\@xl {
      margin-left: 0;
    }
    .margin-x-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@xl {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@xl {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@xl {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@xl {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@xl {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@xl {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@xl {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@xl {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@xl {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@xl {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@xl {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@xl {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@xl {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@xl {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@xl {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@xl {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@xl {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@xl {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@xl {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@xl {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@xl {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@xl {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@xl {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@xl {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@xl {
      padding: var(--space-xxs);
    }
    .padding-xs\@xl {
      padding: var(--space-xs);
    }
    .padding-sm\@xl {
      padding: var(--space-sm);
    }
    .padding-md\@xl {
      padding: var(--space-md);
    }
    .padding-lg\@xl {
      padding: var(--space-lg);
    }
    .padding-xl\@xl {
      padding: var(--space-xl);
    }
    .padding-xxl\@xl {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@xl {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@xl {
      padding: var(--space-xxxxl);
    }
    .padding-0\@xl {
      padding: 0;
    }
    .padding-component\@xl {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@xl {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@xl {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@xl {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@xl {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@xl {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@xl {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@xl {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@xl {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@xl {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@xl {
      padding-top: 0;
    }
    .padding-top-component\@xl {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@xl {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@xl {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@xl {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@xl {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@xl {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@xl {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@xl {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@xl {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@xl {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@xl {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@xl {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@xl {
      padding-bottom: 0;
    }
    .padding-bottom-component\@xl {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@xl {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@xl {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@xl {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@xl {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@xl {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@xl {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@xl {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@xl {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@xl {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@xl {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@xl {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@xl {
      padding-right: 0;
    }
    .padding-right-component\@xl {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@xl {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@xl {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@xl {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@xl {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@xl {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@xl {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@xl {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@xl {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@xl {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@xl {
      padding-left: 0;
    }
    .padding-left-component\@xl {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@xl {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@xl {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@xl {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@xl {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@xl {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@xl {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@xl {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@xl {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@xl {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@xl {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@xl {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@xl {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@xl {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@xl {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@xl {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@xl {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@xl {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@xl {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@xl {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@xl {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@xl {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@xl {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
  .text-justify\@xl {
    text-align: justify;
  }
  @supports (--css: variables) {
    .text-xs\@xl {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@xl {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@xl {
      font-size: var(--text-unit, 1em);
    }
    .text-md\@xl {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@xl {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@xl {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@xl {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@xl {
      font-size: var(--text-xxxl, 2.488em);
    }
    .text-xxxxl\@xl {
      font-size: var(--text-xxxxl, 2.985em);
    }
  }
  .hide\@xl {
    display: none !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
  .has-padding\@xl {
    padding: 0 !important;
  }
  .display\@xl {
    display: none !important;
  }
}

:root, [data-theme="default"] {
  --color-primary-darker: hsl(26, 33%, 0%);
  --color-primary-darker-h: 26;
  --color-primary-darker-s: 33%;
  --color-primary-darker-l: 0%;
  --color-primary-dark: hsl(26, 33%, 9%);
  --color-primary-dark-h: 26;
  --color-primary-dark-s: 33%;
  --color-primary-dark-l: 9%;
  --color-primary: hsl(26, 33%, 19%);
  --color-primary-h: 26;
  --color-primary-s: 33%;
  --color-primary-l: 19%;
  --color-primary-light: hsl(26, 33%, 29%);
  --color-primary-light-h: 26;
  --color-primary-light-s: 33%;
  --color-primary-light-l: 29%;
  --color-primary-lighter: hsl(26, 33%, 39%);
  --color-primary-lighter-h: 26;
  --color-primary-lighter-s: 33%;
  --color-primary-lighter-l: 39%;
  --color-accent-darker: hsl(31, 30%, 65%);
  --color-accent-darker-h: 31;
  --color-accent-darker-s: 30%;
  --color-accent-darker-l: 65%;
  --color-accent-dark: hsl(31, 30%, 75%);
  --color-accent-dark-h: 31;
  --color-accent-dark-s: 30%;
  --color-accent-dark-l: 75%;
  --color-accent: hsl(31, 30%, 85%);
  --color-accent-h: 31;
  --color-accent-s: 30%;
  --color-accent-l: 85%;
  --color-accent-light: hsl(31, 30%, 93%);
  --color-accent-light-h: 31;
  --color-accent-light-s: 30%;
  --color-accent-light-l: 93%;
  --color-accent-lighter: hsl(31, 30%, 100%);
  --color-accent-lighter-h: 31;
  --color-accent-lighter-s: 30%;
  --color-accent-lighter-l: 100%;
  --color-secondary-darker: hsl(40, 20%, 77%);
  --color-secondary-darker-h: 40;
  --color-secondary-darker-s: 20%;
  --color-secondary-darker-l: 77%;
  --color-secondary-dark: hsl(40, 20%, 87%);
  --color-secondary-dark-h: 40;
  --color-secondary-dark-s: 20%;
  --color-secondary-dark-l: 87%;
  --color-secondary: hsl(40, 20%, 97%);
  --color-secondary-h: 40;
  --color-secondary-s: 20%;
  --color-secondary-l: 97%;
  --color-secondary-light: hsl(40, 20%, 99%);
  --color-secondary-light-h: 40;
  --color-secondary-light-s: 20%;
  --color-secondary-light-l: 99%;
  --color-secondary-lighter: hsl(40, 20%, 100%);
  --color-secondary-lighter-h: 40;
  --color-secondary-lighter-s: 20%;
  --color-secondary-lighter-l: 100%;
  --color-black: hsl(220, 4%, 15%);
  --color-black-h: 220;
  --color-black-s: 4%;
  --color-black-l: 15%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: hsl(46, 100%, 41%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 41%;
  --color-warning-dark: hsl(46, 100%, 51%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 51%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 81%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 81%;
  --color-success-darker: hsl(94, 48%, 36%);
  --color-success-darker-h: 94;
  --color-success-darker-s: 48%;
  --color-success-darker-l: 36%;
  --color-success-dark: hsl(94, 48%, 46%);
  --color-success-dark-h: 94;
  --color-success-dark-s: 48%;
  --color-success-dark-l: 46%;
  --color-success: hsl(94, 48%, 56%);
  --color-success-h: 94;
  --color-success-s: 48%;
  --color-success-l: 56%;
  --color-success-light: hsl(94, 48%, 66%);
  --color-success-light-h: 94;
  --color-success-light-s: 48%;
  --color-success-light-l: 66%;
  --color-success-lighter: hsl(94, 48%, 76%);
  --color-success-lighter-h: 94;
  --color-success-lighter-s: 48%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(349, 75%, 31%);
  --color-error-darker-h: 349;
  --color-error-darker-s: 75%;
  --color-error-darker-l: 31%;
  --color-error-dark: hsl(349, 75%, 41%);
  --color-error-dark-h: 349;
  --color-error-dark-s: 75%;
  --color-error-dark-l: 41%;
  --color-error: hsl(349, 75%, 51%);
  --color-error-h: 349;
  --color-error-s: 75%;
  --color-error-l: 51%;
  --color-error-light: hsl(349, 75%, 61%);
  --color-error-light-h: 349;
  --color-error-light-s: 75%;
  --color-error-light-l: 61%;
  --color-error-lighter: hsl(349, 75%, 71%);
  --color-error-lighter-h: 349;
  --color-error-lighter-s: 75%;
  --color-error-lighter-l: 71%;
  --color-bg: hsl(36, 24%, 96%);
  --color-bg-h: 36;
  --color-bg-s: 24%;
  --color-bg-l: 96%;
  --color-contrast-lower: hsl(36, 11%, 91%);
  --color-contrast-lower-h: 36;
  --color-contrast-lower-s: 11%;
  --color-contrast-lower-l: 91%;
  --color-contrast-low: hsl(40, 3%, 80%);
  --color-contrast-low-h: 40;
  --color-contrast-low-s: 3%;
  --color-contrast-low-l: 80%;
  --color-contrast-medium: hsl(270, 1%, 46%);
  --color-contrast-medium-h: 270;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 46%;
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 4%;
  --color-contrast-high-l: 20%;
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 8%;
  --color-contrast-higher-l: 12%;
}

@supports (--css: variables) {
  [data-theme="blue"] {
    --color-primary-darker: hsl(31, 30%, 65%);
    --color-primary-darker-h: 31;
    --color-primary-darker-s: 30%;
    --color-primary-darker-l: 65%;
    --color-primary-dark: hsl(31, 30%, 75%);
    --color-primary-dark-h: 31;
    --color-primary-dark-s: 30%;
    --color-primary-dark-l: 75%;
    --color-primary: hsl(31, 30%, 85%);
    --color-primary-h: 31;
    --color-primary-s: 30%;
    --color-primary-l: 85%;
    --color-primary-light: hsl(31, 30%, 93%);
    --color-primary-light-h: 31;
    --color-primary-light-s: 30%;
    --color-primary-light-l: 93%;
    --color-primary-lighter: hsl(31, 30%, 100%);
    --color-primary-lighter-h: 31;
    --color-primary-lighter-s: 30%;
    --color-primary-lighter-l: 100%;
    --color-accent-darker: hsl(0, 0%, 33%);
    --color-accent-darker-h: 0;
    --color-accent-darker-s: 0%;
    --color-accent-darker-l: 33%;
    --color-accent-dark: hsl(0, 0%, 43%);
    --color-accent-dark-h: 0;
    --color-accent-dark-s: 0%;
    --color-accent-dark-l: 43%;
    --color-accent: hsl(0, 0%, 53%);
    --color-accent-h: 0;
    --color-accent-s: 0%;
    --color-accent-l: 53%;
    --color-accent-light: hsl(0, 0%, 63%);
    --color-accent-light-h: 0;
    --color-accent-light-s: 0%;
    --color-accent-light-l: 63%;
    --color-accent-lighter: hsl(0, 0%, 73%);
    --color-accent-lighter-h: 0;
    --color-accent-lighter-s: 0%;
    --color-accent-lighter-l: 73%;
    --color-secondary-darker: hsl(260, 100%, 0%);
    --color-secondary-darker-h: 260;
    --color-secondary-darker-s: 100%;
    --color-secondary-darker-l: 0%;
    --color-secondary-dark: hsl(260, 100%, 6%);
    --color-secondary-dark-h: 260;
    --color-secondary-dark-s: 100%;
    --color-secondary-dark-l: 6%;
    --color-secondary: hsl(260, 100%, 13%);
    --color-secondary-h: 260;
    --color-secondary-s: 100%;
    --color-secondary-l: 13%;
    --color-secondary-light: hsl(260, 100%, 23%);
    --color-secondary-light-h: 260;
    --color-secondary-light-s: 100%;
    --color-secondary-light-l: 23%;
    --color-secondary-lighter: hsl(260, 100%, 33%);
    --color-secondary-lighter-h: 260;
    --color-secondary-lighter-s: 100%;
    --color-secondary-lighter-l: 33%;
    --color-black: hsl(220, 4%, 15%);
    --color-black-h: 220;
    --color-black-s: 4%;
    --color-black-l: 15%;
    --color-white: hsl(0, 0%, 100%);
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: hsl(46, 100%, 41%);
    --color-warning-darker-h: 46;
    --color-warning-darker-s: 100%;
    --color-warning-darker-l: 41%;
    --color-warning-dark: hsl(46, 100%, 51%);
    --color-warning-dark-h: 46;
    --color-warning-dark-s: 100%;
    --color-warning-dark-l: 51%;
    --color-warning: hsl(46, 100%, 61%);
    --color-warning-h: 46;
    --color-warning-s: 100%;
    --color-warning-l: 61%;
    --color-warning-light: hsl(46, 100%, 71%);
    --color-warning-light-h: 46;
    --color-warning-light-s: 100%;
    --color-warning-light-l: 71%;
    --color-warning-lighter: hsl(46, 100%, 81%);
    --color-warning-lighter-h: 46;
    --color-warning-lighter-s: 100%;
    --color-warning-lighter-l: 81%;
    --color-success-darker: hsl(94, 48%, 36%);
    --color-success-darker-h: 94;
    --color-success-darker-s: 48%;
    --color-success-darker-l: 36%;
    --color-success-dark: hsl(94, 48%, 46%);
    --color-success-dark-h: 94;
    --color-success-dark-s: 48%;
    --color-success-dark-l: 46%;
    --color-success: hsl(94, 48%, 56%);
    --color-success-h: 94;
    --color-success-s: 48%;
    --color-success-l: 56%;
    --color-success-light: hsl(94, 48%, 66%);
    --color-success-light-h: 94;
    --color-success-light-s: 48%;
    --color-success-light-l: 66%;
    --color-success-lighter: hsl(94, 48%, 76%);
    --color-success-lighter-h: 94;
    --color-success-lighter-s: 48%;
    --color-success-lighter-l: 76%;
    --color-error-darker: hsl(349, 75%, 31%);
    --color-error-darker-h: 349;
    --color-error-darker-s: 75%;
    --color-error-darker-l: 31%;
    --color-error-dark: hsl(349, 75%, 41%);
    --color-error-dark-h: 349;
    --color-error-dark-s: 75%;
    --color-error-dark-l: 41%;
    --color-error: hsl(349, 75%, 51%);
    --color-error-h: 349;
    --color-error-s: 75%;
    --color-error-l: 51%;
    --color-error-light: hsl(349, 75%, 61%);
    --color-error-light-h: 349;
    --color-error-light-s: 75%;
    --color-error-light-l: 61%;
    --color-error-lighter: hsl(349, 75%, 71%);
    --color-error-lighter-h: 349;
    --color-error-lighter-s: 75%;
    --color-error-lighter-l: 71%;
    --color-bg: hsl(26, 33%, 19%);
    --color-bg-h: 26;
    --color-bg-s: 33%;
    --color-bg-l: 19%;
    --color-contrast-lower: hsl(25, 27%, 22%);
    --color-contrast-lower-h: 25;
    --color-contrast-lower-s: 27%;
    --color-contrast-lower-l: 22%;
    --color-contrast-low: hsl(26, 19%, 31%);
    --color-contrast-low-h: 26;
    --color-contrast-low-s: 19%;
    --color-contrast-low-l: 31%;
    --color-contrast-medium: hsl(26, 10%, 59%);
    --color-contrast-medium-h: 26;
    --color-contrast-medium-s: 10%;
    --color-contrast-medium-l: 59%;
    --color-contrast-high: hsl(33, 13%, 86%);
    --color-contrast-high-h: 33;
    --color-contrast-high-s: 13%;
    --color-contrast-high-l: 86%;
    --color-contrast-higher: hsl(36, 24%, 96%);
    --color-contrast-higher-h: 36;
    --color-contrast-higher-s: 24%;
    --color-contrast-higher-l: 96%;
  }
}

@supports (--css: variables) {
  [data-theme="grey"] {
    --color-primary-darker: hsl(26, 33%, 0%);
    --color-primary-darker-h: 26;
    --color-primary-darker-s: 33%;
    --color-primary-darker-l: 0%;
    --color-primary-dark: hsl(26, 33%, 9%);
    --color-primary-dark-h: 26;
    --color-primary-dark-s: 33%;
    --color-primary-dark-l: 9%;
    --color-primary: hsl(26, 33%, 19%);
    --color-primary-h: 26;
    --color-primary-s: 33%;
    --color-primary-l: 19%;
    --color-primary-light: hsl(26, 33%, 29%);
    --color-primary-light-h: 26;
    --color-primary-light-s: 33%;
    --color-primary-light-l: 29%;
    --color-primary-lighter: hsl(26, 33%, 39%);
    --color-primary-lighter-h: 26;
    --color-primary-lighter-s: 33%;
    --color-primary-lighter-l: 39%;
    --color-accent-darker: hsl(24, 11%, 62%);
    --color-accent-darker-h: 24;
    --color-accent-darker-s: 11%;
    --color-accent-darker-l: 62%;
    --color-accent-dark: hsl(24, 11%, 72%);
    --color-accent-dark-h: 24;
    --color-accent-dark-s: 11%;
    --color-accent-dark-l: 72%;
    --color-accent: hsl(24, 11%, 82%);
    --color-accent-h: 24;
    --color-accent-s: 11%;
    --color-accent-l: 82%;
    --color-accent-light: hsl(24, 11%, 91%);
    --color-accent-light-h: 24;
    --color-accent-light-s: 11%;
    --color-accent-light-l: 91%;
    --color-accent-lighter: hsl(24, 11%, 100%);
    --color-accent-lighter-h: 24;
    --color-accent-lighter-s: 11%;
    --color-accent-lighter-l: 100%;
    --color-secondary-darker: hsl(202, 22%, 29%);
    --color-secondary-darker-h: 202;
    --color-secondary-darker-s: 22%;
    --color-secondary-darker-l: 29%;
    --color-secondary-dark: hsl(202, 22%, 39%);
    --color-secondary-dark-h: 202;
    --color-secondary-dark-s: 22%;
    --color-secondary-dark-l: 39%;
    --color-secondary: hsl(202, 22%, 49%);
    --color-secondary-h: 202;
    --color-secondary-s: 22%;
    --color-secondary-l: 49%;
    --color-secondary-light: hsl(202, 22%, 59%);
    --color-secondary-light-h: 202;
    --color-secondary-light-s: 22%;
    --color-secondary-light-l: 59%;
    --color-secondary-lighter: hsl(202, 22%, 69%);
    --color-secondary-lighter-h: 202;
    --color-secondary-lighter-s: 22%;
    --color-secondary-lighter-l: 69%;
    --color-black: hsl(220, 4%, 15%);
    --color-black-h: 220;
    --color-black-s: 4%;
    --color-black-l: 15%;
    --color-white: hsl(0, 0%, 100%);
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: hsl(46, 100%, 41%);
    --color-warning-darker-h: 46;
    --color-warning-darker-s: 100%;
    --color-warning-darker-l: 41%;
    --color-warning-dark: hsl(46, 100%, 51%);
    --color-warning-dark-h: 46;
    --color-warning-dark-s: 100%;
    --color-warning-dark-l: 51%;
    --color-warning: hsl(46, 100%, 61%);
    --color-warning-h: 46;
    --color-warning-s: 100%;
    --color-warning-l: 61%;
    --color-warning-light: hsl(46, 100%, 71%);
    --color-warning-light-h: 46;
    --color-warning-light-s: 100%;
    --color-warning-light-l: 71%;
    --color-warning-lighter: hsl(46, 100%, 81%);
    --color-warning-lighter-h: 46;
    --color-warning-lighter-s: 100%;
    --color-warning-lighter-l: 81%;
    --color-success-darker: hsl(94, 48%, 36%);
    --color-success-darker-h: 94;
    --color-success-darker-s: 48%;
    --color-success-darker-l: 36%;
    --color-success-dark: hsl(94, 48%, 46%);
    --color-success-dark-h: 94;
    --color-success-dark-s: 48%;
    --color-success-dark-l: 46%;
    --color-success: hsl(94, 48%, 56%);
    --color-success-h: 94;
    --color-success-s: 48%;
    --color-success-l: 56%;
    --color-success-light: hsl(94, 48%, 66%);
    --color-success-light-h: 94;
    --color-success-light-s: 48%;
    --color-success-light-l: 66%;
    --color-success-lighter: hsl(94, 48%, 76%);
    --color-success-lighter-h: 94;
    --color-success-lighter-s: 48%;
    --color-success-lighter-l: 76%;
    --color-error-darker: hsl(349, 75%, 31%);
    --color-error-darker-h: 349;
    --color-error-darker-s: 75%;
    --color-error-darker-l: 31%;
    --color-error-dark: hsl(349, 75%, 41%);
    --color-error-dark-h: 349;
    --color-error-dark-s: 75%;
    --color-error-dark-l: 41%;
    --color-error: hsl(349, 75%, 51%);
    --color-error-h: 349;
    --color-error-s: 75%;
    --color-error-l: 51%;
    --color-error-light: hsl(349, 75%, 61%);
    --color-error-light-h: 349;
    --color-error-light-s: 75%;
    --color-error-light-l: 61%;
    --color-error-lighter: hsl(349, 75%, 71%);
    --color-error-lighter-h: 349;
    --color-error-lighter-s: 75%;
    --color-error-lighter-l: 71%;
    --color-bg: hsl(31, 30%, 85%);
    --color-bg-h: 31;
    --color-bg-s: 30%;
    --color-bg-l: 85%;
    --color-contrast-lower: hsl(30, 22%, 81%);
    --color-contrast-lower-h: 30;
    --color-contrast-lower-s: 22%;
    --color-contrast-lower-l: 81%;
    --color-contrast-low: hsl(28, 12%, 71%);
    --color-contrast-low-h: 28;
    --color-contrast-low-s: 12%;
    --color-contrast-low-l: 71%;
    --color-contrast-medium: hsl(20, 3%, 42%);
    --color-contrast-medium-h: 20;
    --color-contrast-medium-s: 3%;
    --color-contrast-medium-l: 42%;
    --color-contrast-high: hsl(270, 4%, 18%);
    --color-contrast-high-h: 270;
    --color-contrast-high-s: 4%;
    --color-contrast-high-l: 18%;
    --color-contrast-higher: hsl(240, 8%, 12%);
    --color-contrast-higher-h: 240;
    --color-contrast-higher-s: 8%;
    --color-contrast-higher-l: 12%;
  }
}

.bg-secondary-darker {
  background-color: hsla(var(--color-secondary-darker-h), var(--color-secondary-darker-s), var(--color-secondary-darker-l), var(--bg-o, 1));
}

.bg-secondary-dark {
  background-color: hsla(var(--color-secondary-dark-h), var(--color-secondary-dark-s), var(--color-secondary-dark-l), var(--bg-o, 1));
}

.bg-secondary {
  background-color: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--bg-o, 1));
}

.bg-secondary-light {
  background-color: hsla(var(--color-secondary-light-h), var(--color-secondary-light-s), var(--color-secondary-light-l), var(--bg-o, 1));
}

.bg-secondary-lighter {
  background-color: hsla(var(--color-secondary-lighter-h), var(--color-secondary-lighter-s), var(--color-secondary-lighter-l), var(--bg-o, 1));
}

.border-secondary-darker {
  border-color: hsla(var(--color-secondary-darker-h), var(--color-secondary-darker-s), var(--color-secondary-darker-l), var(--bg-o, 1));
}

.border-secondary-dark {
  border-color: hsla(var(--color-secondary-dark-h), var(--color-secondary-dark-s), var(--color-secondary-dark-l), var(--bg-o, 1));
}

.border-secondary {
  border-color: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--bg-o, 1));
}

.border-secondary-light {
  border-color: hsla(var(--color-secondary-light-h), var(--color-secondary-light-s), var(--color-secondary-light-l), var(--bg-o, 1));
}

.border-secondary-lighter {
  border-color: hsla(var(--color-secondary-lighter-h), var(--color-secondary-lighter-s), var(--color-secondary-lighter-l), var(--bg-o, 1));
}

:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-xs);
}

@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.3em;
      --space-xxxxs: calc(0.09 * var(--space-unit));
      --space-xxxs: calc(0.146 * var(--space-unit));
      --space-xxs: calc(0.236 * var(--space-unit));
      --space-xs: calc(0.382 * var(--space-unit));
      --space-sm: calc(0.618 * var(--space-unit));
      --space-md: calc(1 * var(--space-unit));
      --space-lg: calc(1.618 * var(--space-unit));
      --space-xl: calc(2.618 * var(--space-unit));
      --space-xxl: calc(4.236 * var(--space-unit));
      --space-xxxl: calc(6.854 * var(--space-unit));
      --space-xxxxl: calc(11.08 * var(--space-unit));
    }
  }
}

:root {
  --font-primary: 'DM Serif Display', serif;
  --font-secondary: 'DM Sans', sans-serif;
  --text-base-size: 1.4em;
  --text-scale-ratio: 1.2;
  --body-line-height: 1.4;
  --heading-line-height: 1.4;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
  --text-unit: 1em;
}

:root, * {
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.1em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-secondary);
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: var(--font-primary);
  font-weight: 700;
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

.color-secondary-darker {
  color: hsla(var(--color-secondary-darker-h), var(--color-secondary-darker-s), var(--color-secondary-darker-l), var(--color-o, 1));
}

.color-secondary-dark {
  color: hsla(var(--color-secondary-dark-h), var(--color-secondary-dark-s), var(--color-secondary-dark-l), var(--color-o, 1));
}

.color-secondary {
  color: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--color-o, 1));
}

.color-secondary-light {
  color: hsla(var(--color-secondary-light-h), var(--color-secondary-light-s), var(--color-secondary-light-l), var(--color-o, 1));
}

.color-secondary-lighter {
  color: hsla(var(--color-secondary-lighter-h), var(--color-secondary-lighter-s), var(--color-secondary-lighter-l), var(--color-o, 1));
}

.no-underline {
  text-decoration: none !important;
}

body {
  position: relative;
  overflow-x: hidden;
}

body #watermerk {
  top: 24vh;
  bottom: 0;
  position: absolute;
  width: 60vw;
  height: auto;
  z-index: -3;
  right: -10vw;
  fill: #f4f4f4;
}

/* -------------------------------- 

File#: _1_article-gallery-v3
Title: Article Gallery v3
Descr: A gallery of blog articles
Usage: codyhouse.co/license

-------------------------------- */
.articles-v3__img {
  display: block;
  transition: opacity 0.3s;
  overflow: hidden;
}

.articles-v3__img img {
  display: block;
  width: 100%;
}

.articles-v3__img:hover {
  opacity: 0.85;
}

.articles-v3__headline {
  font-size: var(--text-xl);
}

.articles-v3__headline a {
  color: var(--color-contrast-higher);
  text-decoration: none;
}

.articles-v3__headline a:hover {
  text-decoration: underline;
}

.articles-v3__author {
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-gap: var(--space-xs);
  align-items: center;
  margin-top: var(--space-md);
}

.articles-v3__author-img {
  display: block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.3s var(--bounce);
}

.articles-v3__author-img img {
  display: block;
  width: inherit;
  height: inherit;
}

.articles-v3__author-img:hover {
  transform: scale(1.1);
}

.articles-v3__author-name {
  font-weight: bold;
  color: var(--color-contrast-higher);
  text-decoration: none;
}

.articles-v3__author-name:hover {
  text-decoration: underline;
}

:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-lg);
  --btn-padding-y: var(--space-sm);
  --btn-radius: 0;
}

.btn {
  cursor: pointer;
  font-family: var(--font-secondary);
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  box-shadow: none;
  transition: all 0.3s ease;
}

.btn:hover {
  box-shadow: 0px 1px 2px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12), 0px 1px 1px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12);
}

.btn:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
  outline: none;
}

.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn--primary:hover {
  background-color: var(--color-primary-dark);
}

.btn--primary:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.btn--subtle {
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn--accent:hover {
  background-color: var(--color-accent-dark);
}

.btn--accent:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
}

.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}

.form-control {
  background-color: var(--color-bg);
  border: 2px solid var(--color-contrast-low);
}

.form-control::-webkit-input-placeholder {
  color: var(--color-contrast-medium);
}

.form-control::-moz-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:-ms-input-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:-moz-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:focus {
  border-color: var(--color-primary);
  box-shadow: 0px 0px 0px 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline: none;
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);
}

.form-control[aria-invalid="true"]:focus {
  box-shadow: 0px 0px 0px 3px hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.form-legend {
  color: var(--color-contrast-higher);
  font-size: var(--text-md);
  line-height: 1.2;
  margin-bottom: var(--space-xxs);
}

.form-label {
  font-size: var(--text-sm);
}

/* -------------------------------- 

File#: _2_form-template
Title: Form Template
Descr: Form template example
Usage: codyhouse.co/license

-------------------------------- */
.t-form {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-xs);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.t-form__img {
  height: 80px;
  background: url("../../../../../app/assets/img/form-template-img-1.jpg") no-repeat center center;
  background-size: cover;
}

.t-form__content {
  padding: var(--space-md);
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .t-form {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
    .t-form__img {
      height: 100%;
    }
  }
}

/* -------------------------------- 

File#: _1_date-picker
Title: Date Picker
Descr: Input element used to select a date
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --date-picker-trigger-width: 2.5em;
  --date-picker-icon-size: 1.2em;
  --date-picker-dropdown-gap: 4px;
}

.date-input, .date-input__wrapper {
  position: relative;
}

.date-input__trigger {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: var(--date-picker-trigger-width);
  display: none;
}

.date-input__trigger .icon {
  width: var(--date-picker-icon-size);
  height: var(--date-picker-icon-size);
}

.date-input__trigger:focus {
  color: var(--color-primary);
}

.js .date-input__trigger {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.js .date-input__text {
  padding-right: calc(var(--date-picker-trigger-width) + var(--space-xxxs));
}

.date-input--hide-calendar .date-input__trigger {
  display: none;
}

.date-picker {
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(var(--date-picker-dropdown-gap) + 100%);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius-md);
  z-index: var(--zindex-popover);
  user-select: none;
  --space-unit:  1rem;
  --text-unit: 1rem;
  font-size: var(--text-unit);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s;
}

.date-picker--is-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity .2s;
}

.date-picker__header {
  background-color: var(--color-contrast-lower);
  padding-top: var(--space-xxs);
}

.date-picker__month {
  position: relative;
  padding: 0 var(--space-xxs);
}

.date-picker__month-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--color-contrast-higher);
  font-size: 1.1em;
}

.date-picker__month-nav,
.date-picker__week,
.date-picker__dates {
  display: flex;
  flex-wrap: wrap;
  margin: calc(0 * -1) 0 0 calc(0 * -1);
  min-width: 14em;
  display: grid;
  grid-template-columns: repeat(7, minmax(2em, 2.5em));
  grid-gap: var(--space-xxxs);
}

.date-picker__month-nav > *,
.date-picker__week > *,
.date-picker__dates > * {
  padding: 0 0 0 0;
  background-clip: content-box;
}

@supports (grid-area: auto) {
  .date-picker__month-nav,
  .date-picker__week,
  .date-picker__dates {
    margin: 0;
  }
  .date-picker__month-nav > *,
  .date-picker__week > *,
  .date-picker__dates > * {
    padding: 0;
    max-width: none !important;
  }
}

.date-picker__month-nav li,
.date-picker__week li,
.date-picker__dates li {
  flex-basis: 14.28%;
  max-width: 14.28%;
}

.date-picker__month-nav {
  justify-content: space-between;
}

.date-picker__month-nav li {
  position: relative;
}

.date-picker__month-nav li:last-child {
  grid-column: 7 / 8;
}

.date-picker__month-nav-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  width: 100%;
  height: 32px;
  border-radius: var(--radius-md);
  transition: .2s;
  color: var(--color-contrast-medium);
}

.date-picker__month-nav-btn .icon {
  display: block;
  margin: auto;
}

.date-picker__month-nav-btn:hover {
  background-color: var(--color-contrast-low);
  color: var(--color-contrast-high);
}

.date-picker__month-nav-btn:focus {
  outline: none;
  background-color: var(--color-contrast-high);
  color: var(--color-bg);
}

.date-picker__month-nav-btn:active {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.9);
}

.date-picker__week {
  padding: 0 var(--space-xxs);
}

.date-picker__day {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875em;
  color: var(--color-contrast-medium);
}

.date-picker__dates {
  padding: var(--space-xxs);
}

.date-picker__date {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  width: 100%;
  height: 32px;
  text-align: center;
  border-radius: var(--radius-md);
  transition: .2s;
}

.date-picker__date:hover {
  background-color: var(--color-contrast-lower);
}

.date-picker__date[tabindex="0"] {
  color: var(--color-primary);
}

.date-picker__date:focus {
  outline: none;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
  color: var(--color-contrast-higher);
}

.date-picker__date.date-picker__date--selected {
  background-color: var(--color-primary);
  color: var(--color-white);
}

@supports (grid-area: auto) {
  .date-picker__week li,
  .date-picker__dates li,
  .date-picker__month-nav li:last-child {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
  .date-picker__day,
  .date-picker__date,
  .date-picker__month-nav-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.date-picker__date::-moz-focus-inner {
  border: none;
}

/* -------------------------------- 

File#: _2_contact
Title: Contact
Descr: Contact block w/ info about how to get in touch
Usage: codyhouse.co/license

-------------------------------- */
.contact {
  position: relative;
  z-index: 1;
}

.contact .details-list {
  margin-bottom: var(--space-lg);
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .contact .details-list {
      margin-bottom: 0;
    }
    .contact .google-maps {
      height: auto;
      padding-bottom: 0;
    }
    .contact__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--space-lg);
    }
  }
}

/* -------------------------------- 

File#: _1_google-maps
Title: Google Maps
Descr: Google Maps component
Usage: codyhouse.co/license

-------------------------------- */
.google-maps {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.google-maps--ratio-4\:1 {
  padding-bottom: 25%;
}

.google-maps--ratio-3\:1 {
  padding-bottom: 33%;
}

/* -------------------------------- 

File#: _1_details-list
Title: Details List
Descr: Pairs of related information displayed in a list
Usage: codyhouse.co/license

-------------------------------- */
.details-list__item {
  padding: var(--space-md) 0;
  border-bottom: 1px solid var(--color-contrast-low);
}

.details-list__item > * {
  margin-bottom: var(--space-xxs);
}

.details-list__item dd:last-of-type {
  margin-bottom: 0;
}

.details-list__dt {
  font-weight: bold;
}

.details-list__dd {
  line-height: 1.4;
}

.details-list__dd--cta {
  margin-top: var(--space-lg);
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .details-list__item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .details-list__item > * {
      margin-bottom: 0;
    }
    dd:last-of-type {
      text-align: right;
    }
    .details-list__dd--cta {
      margin-top: 0;
    }
  }
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .details-list--col-3 .details-list__item {
      display: grid;
      justify-content: start;
      grid-template-columns: 30% 50% 20%;
    }
  }
}

/* -------------------------------- 

File#: _1_pre-header
Title: Pre-header 
Descr: Pre-header (top) banner
Usage: codyhouse.co/license

-------------------------------- */
.pre-header {
  display: block;
  padding: var(--space-xs) 0;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pre-header--is-hidden {
  display: none;
}

.pre-header__container {
  position: relative;
}

.pre-header__msg {
  font-size: var(--text-sm);
  padding: 0;
  line-height: 1.4;
}

.pre-header__msg a {
  color: inherit;
}

.pre-header__close-btn {
  position: absolute;
  right: 0;
  top: calc(50% - 0.5em);
}

.pre-header__close-btn .icon {
  display: block;
}

@media (min-width: 64rem) {
  .pre-header--text-center {
    text-align: center;
  }
  .pre-header--text-center .pre-header__msg {
    padding: 0 var(--space-lg);
  }
}

a.pre-header {
  text-decoration: none;
  transition: background-color .2s;
}

a.pre-header:hover {
  text-decoration: underline;
  background-color: var(--color-primary-dark);
}

/* -------------------------------- 

File#: _2_flexi-header
Title: Flexi Header
Descr: Customizable header template
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --f-header-height: 80px;
  --f-header-logo-width: 320px;
}

@media (min-width: 64rem) {
  :root {
    --f-header-height: 70px;
  }
}

.f-header {
  height: var(--f-header-height);
  width: 100%;
  z-index: 9999;
  position: relative;
}

.f-header__mobile-content {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.f-header__logo {
  display: block;
  width: var(--f-header-logo-width);
  flex-shrink: 0;
  text-decoration: none;
}

.f-header__logo svg, .f-header__logo img {
  display: block;
}

.f-header__nav-control {
  --anim-menu-btn-size: 40px;
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.f-header__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 0 0 var(--space-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  font-size: var(--text-md);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1em);
}

.f-header__nav::before {
  content: '';
  display: block;
  position: sticky;
  top: 0;
  height: var(--f-header-height);
  background-color: inherit;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__nav--is-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0s, opacity .3s, transform .3s;
}

.f-header__nav-logo-wrapper {
  display: none;
}

.f-header__item {
  flex-shrink: 0;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__dropdown-icon {
  display: none;
}

.f-header__link,
.f-header__dropdown-link {
  display: block;
  display: flex;
  align-items: center;
  color: var(--color-contrast-high);
  text-decoration: none;
  padding: var(--space-xs) 0;
}

.f-header__link:hover, .f-header__link[aria-current="page"],
.f-header__dropdown-link:hover,
.f-header__dropdown-link[aria-current="page"] {
  color: var(--color-primary);
}

.f-header__link .icon,
.f-header__dropdown-link .icon {
  flex-shrink: 0;
}

.f-header__btn,
.f-header__form-control {
  width: 100%;
  margin: var(--space-xs) 0;
}

.f-header__dropdown {
  padding-left: var(--space-md);
}

@media (min-width: 64rem) {
  .f-header__mobile-content {
    display: none;
  }
  .f-header__nav {
    position: static;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    max-height: none;
    overflow: visible;
    overscroll-behavior: auto;
    height: var(--f-header-height);
    font-size: 0.875em;
  }
  .f-header__nav::before {
    display: none;
  }
  .f-header__nav-logo-wrapper {
    display: block;
    display: flex;
  }
  .f-header__nav-grid,
  .f-header__list {
    display: flex;
    align-items: center;
  }
  .f-header__nav-grid {
    height: 100%;
  }
  .f-header__item {
    display: inline-block;
    position: relative;
    border-bottom: none;
    margin-right: var(--space-xs);
  }
  .f-header__item:last-child {
    margin-right: 0;
  }
  .f-header__dropdown-icon {
    display: block;
    margin-left: var(--space-xxxxs);
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }
  .f-header__link {
    padding: var(--space-xxxs) var(--space-xxs);
  }
  .f-header__link--icon span {
    display: none;
  }
  .f-header__btn,
  .f-header__form-control {
    margin: 0;
  }
  .f-header__dropdown {
    font-size: 1rem;
    position: absolute;
    top: 100%;
    width: 200px;
    left: calc(50% - 100px);
    padding: var(--space-xxxs) 0;
    background-color: var(--color-bg);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-md);
    z-index: var(--zindex-popover);
    visibility: hidden;
    opacity: 0;
    transition: visibility .2s .2s, opacity .2s 0s;
  }
  .f-header__item:hover .f-header__dropdown {
    visibility: visible;
    opacity: 1;
    transition: visibility .2s 0s, opacity .2s 0s;
  }
  .f-header__dropdown-link {
    color: var(--color-contrast-high);
    padding: var(--space-xs) var(--space-sm);
  }
  .f-header__dropdown-link:hover {
    background-color: var(--color-contrast-lower);
  }
}

html:not(.js) .f-header__nav-control {
  display: none;
}

html:not(.js) .f-header__nav {
  position: static;
  padding: var(--space-xxs) 0 var(--space-md);
  box-shadow: none;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

/* -------------------------------- 

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: A menu button w/ a morphing icon
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-menu-btn-size: 48px;
  --anim-menu-btn-icon-size: 32px;
  --anim-menu-btn-icon-stroke: 2px;
}

.anim-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--anim-menu-btn-size);
  height: var(--anim-menu-btn-size);
  color: var(--color-contrast-high);
}

.anim-menu-btn__icon {
  position: relative;
  display: block;
  font-size: var(--anim-menu-btn-icon-size);
  width: 1em;
  height: var(--anim-menu-btn-icon-stroke);
  color: inherit;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  transform: scale(1);
}

.anim-menu-btn__icon::before, .anim-menu-btn__icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: inherit;
  border-radius: inherit;
}

.anim-menu-btn__icon--close {
  background-size: 100% 100%;
  will-change: transform, background-size;
  transition: transform .2s, background-size .2s;
}

.anim-menu-btn:active .anim-menu-btn__icon--close {
  transform: scale(0.9);
}

.anim-menu-btn__icon--close::before, .anim-menu-btn__icon--close::after {
  will-change: inherit;
  transition: inherit;
}

.anim-menu-btn__icon--close::before {
  transform: translateY(-0.25em) rotate(0);
}

.anim-menu-btn__icon--close::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close {
  background-size: 0% 100%;
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close::before {
  transform: translateY(0) rotate(45deg);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close::after {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn__icon--arrow-left,
.anim-menu-btn__icon--arrow-right,
.anim-menu-btn__icon--arrow-up,
.anim-menu-btn__icon--arrow-down {
  border-radius: 50em;
  will-change: transform;
  transition: transform .2s;
}

.anim-menu-btn:active .anim-menu-btn__icon--arrow-left, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-right, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-up, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-down {
  transform: scale(0.9);
}

.anim-menu-btn__icon--arrow-left::before, .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn__icon--arrow-down::after {
  transform-origin: calc(var(--anim-menu-btn-icon-stroke)/2) 50%;
  will-change: transform, width;
  transition: transform .2s, width .2s;
}

.anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-down::before {
  transform: translateY(-0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg);
}

.anim-menu-btn:active .anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before, .anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  width: 50%;
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0) rotate(45deg);
}

.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg);
}

.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg);
}

/* -------------------------------- 

File#: _1_search-input
Title: Search input
Descr: Search input field with custom button
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --search-input-btn-width: 2.5em;
  --search-input-icon-size: 1.25em;
}

.search-input {
  position: relative;
}

.search-input__input {
  width: 100%;
  height: 100%;
}

.search-input__input::-webkit-search-decoration, .search-input__input::-webkit-search-cancel-button, .search-input__input::-webkit-search-results-button, .search-input__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.search-input__input::-ms-clear, .search-input__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.search-input--icon-right .search-input__input {
  padding-right: var(--search-input-btn-width);
}

.search-input--icon-left .search-input__input {
  padding-left: var(--search-input-btn-width);
}

.search-input__btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--search-input-btn-width);
}

.search-input__btn:active .icon {
  transform: translateY(2px);
}

.search-input__btn .icon {
  display: block;
  width: var(--search-input-icon-size);
  height: var(--search-input-icon-size);
  margin-left: auto;
  margin-right: auto;
  color: var(--color-contrast-medium);
  transition: .2s;
}

.search-input--icon-left .search-input__btn {
  left: 0;
  right: auto;
  pointer-events: none;
}

.search-input__btn:focus .icon,
.search-input .search-input__input:focus + .search-input__btn .icon {
  color: var(--color-primary);
}

/* -------------------------------- 

File#: _1_section-divider
Title: Section divider
Descr: A collection of SVG section dividers.
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --section-divider-width: 1920;
  --section-divider-height: 60;
  --section-divider-ratio: calc(
    100% * var(--section-divider-height) / var(--section-divider-width)
  );
}

[class*="has-section-divider"] {
  position: relative;
}

.has-section-dividers {
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);
}

.has-section-dividers .section-divider:first-child {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-top {
  padding-top: var(--section-divider-ratio);
}

.has-section-divider-top .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-bottom {
  padding-bottom: var(--section-divider-ratio);
}

.has-bg-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.has-bg-image .bg-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(var(--color-primary-light), 0.2);
}

.section-divider {
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: auto;
  width: 100%;
}

.hero {
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  min-height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 64rem) {
  .hero {
    min-height: 540px;
  }
}

.hero__label {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.6);
  font-size: var(--text-sm);
}

.hero--overlay-layer {
  position: relative;
}

.hero--overlay-layer .hero__content {
  position: relative;
  z-index: 2;
}

.hero--overlay-layer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.65);
  z-index: 1;
}

.hero--full-screen {
  height: 100vh;
}

.cta__block {
  background: var(--color-primary);
  height: 160px;
  width: 44%;
  position: relative;
  top: -66px;
  left: 91px;
  cursor: pointer;
  padding: 60px 40px;
  text: center;
}

.cta__block:hover {
  text-decoration: none !important;
  background: var(--color-primary-dark);
}

.cta__block h3 {
  display: flex;
  align-items: center;
}

.cta__block-arrow {
  margin-left: 32px;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  background: var(--color-primary-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta__block-arrow svg #icon {
  fill: #fff;
}

.img--styled {
  position: relative;
}

.img--styled:before {
  display: block;
  position: absolute;
  right: -2rem;
  bottom: -2rem;
  border-radius: 2rem 2rem 0 0;
  background: var(--color-accent-light);
  width: 100%;
  height: calc(100% - 2rem);
  content: "";
}

@media screen and (max-width: 768px) {
  .img--styled:before {
    right: -1rem;
    bottom: -1rem;
    border-radius: 1rem 1rem 0 0;
  }
}

.img--styled img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem 2rem 0 5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 768px) {
  .img--styled img {
    border-radius: 0.6rem 0.6rem 0 2rem;
  }
}

/* -------------------------------- 

File#: _2_features-v3
Title: Features v3
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */
.features-v3__text {
  padding-top: var(--space-xxl);
  padding-bottom: calc(var(--space-xl) * 2);
  background-color: var(--color-contrast-lower);
}

.features-v3__cards {
  margin-top: calc(var(--space-xl) * -1);
}

@media screen and (max-width: 768px) {
  .features-v3__cards {
    margin-top: 0;
  }
}

/* -------------------------------- 

File#: _1_feature
Title: Feature
Descr: Content group containing text + media (img, video, iframe, inline SVG)
Usage: codyhouse.co/license

-------------------------------- */
.feature .container {
  position: relative;
}

.feature .container.no--btn:before {
  content: '';
  position: absolute;
  width: 70vw;
  height: 100%;
  background-size: 600px auto;
  background: #f4f4f4 url("/assets/img/watermerk.svg") no-repeat left bottom;
  z-index: -1;
  top: -100px;
}

@media screen and (max-width: 768px) {
  .feature .container.no--btn:before {
    display: none;
  }
}

/* -------------------------------- 

File#: _1_feature-v3
Title: Feature v3
Descr: Content group containing text + media element that outsets its container at a specific viewport size
Usage: codyhouse.co/license

-------------------------------- */
.feature-v3--media-outset\@sm {
  overflow: hidden;
}

@media (min-width: 48rem) {
  .feature-v3--media-outset\@sm .feature-v3__grid {
    align-items: stretch;
  }
  .feature-v3--media-outset\@sm .feature-v3__media {
    position: relative;
    height: 100%;
  }
  .feature-v3--media-outset\@sm .feature-v3__media > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: none;
    width: auto;
  }
}

@media (min-width: 48rem) {
  .feature-v3--media-outset\@sm.feature-v3--invert\@sm .feature-v3__grid > *:last-child {
    order: -1;
  }
  .feature-v3--media-outset\@sm.feature-v3--invert\@sm .feature-v3__grid > *:last-child .feature-v3__media > * {
    left: auto;
    right: 0;
  }
}

.feature-v3--media-outset\@md {
  overflow: hidden;
}

@media (min-width: 64rem) {
  .feature-v3--media-outset\@md .feature-v3__grid {
    align-items: stretch;
  }
  .feature-v3--media-outset\@md .feature-v3__media {
    position: relative;
    height: 100%;
  }
  .feature-v3--media-outset\@md .feature-v3__media > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: none;
    width: auto;
  }
}

@media (min-width: 64rem) {
  .feature-v3--media-outset\@md.feature-v3--invert\@md .feature-v3__grid > *:last-child {
    order: -1;
  }
  .feature-v3--media-outset\@md.feature-v3--invert\@md .feature-v3__grid > *:last-child .feature-v3__media > * {
    left: auto;
    right: 0;
  }
}

.feature-v3--media-outset\@lg {
  overflow: hidden;
}

@media (min-width: 80rem) {
  .feature-v3--media-outset\@lg .feature-v3__grid {
    align-items: stretch;
  }
  .feature-v3--media-outset\@lg .feature-v3__media {
    position: relative;
    height: 100%;
  }
  .feature-v3--media-outset\@lg .feature-v3__media > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: none;
    width: auto;
  }
}

@media (min-width: 80rem) {
  .feature-v3--media-outset\@lg.feature-v3--invert\@lg .feature-v3__grid > *:last-child {
    order: -1;
  }
  .feature-v3--media-outset\@lg.feature-v3--invert\@lg .feature-v3__grid > *:last-child .feature-v3__media > * {
    left: auto;
    right: 0;
  }
}

/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */
.feature-v4 {
  --feature-text-offset: 65%;
  position: relative;
  z-index: 1;
}

@media (min-width: 32rem) {
  .feature-v4__text-offset\@xs {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@xs .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@xs .feature-v4__text-offset\@xs {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 48rem) {
  .feature-v4__text-offset\@sm {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@sm .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@sm .feature-v4__text-offset\@sm {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 64rem) {
  .feature-v4__text-offset\@md {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@md .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@md .feature-v4__text-offset\@md {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 80rem) {
  .feature-v4__text-offset\@lg {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@lg .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@lg .feature-v4__text-offset\@lg {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 90rem) {
  .feature-v4__text-offset\@xl {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@xl .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@xl .feature-v4__text-offset\@xl {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

.icon__container {
  background: var(--color-accent-light);
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon__container img {
  display: block;
  margin: auto;
  width: 32px;
}

/* -------------------------------- 

File#: _3_feature-v14
Title: Feature v14
Descr: Feature content with tech devices
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_devices
Title: Devices
Descr: A collection of CSS tech devices
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --dev-phone-radius: 14%;
  --dev-phone-img-ratio: 0.56;
  --dev-tablet-radius: 6%;
  --dev-tablet-img-ratio: 0.75;
  --dev-browser-radius: 1.5%;
  --dev-browser-img-ratio: 1.6;
  --dev-laptop-radius: 3%;
  --dev-laptop-img-ratio: 1.6;
  --dev-desktop-radius: 3%;
  --dev-desktop-img-ratio: 1.6;
}

/* #region (Phone) */
.dev-phone, .dev-phone__body {
  position: relative;
  width: 100%;
}

.dev-phone {
  z-index: 1;
}

.dev-phone__body {
  background-color: var(--color-bg);
  --phone-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035), inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--phone-inner-shadow);
  padding: 3%;
  z-index: 2;
  border-top-left-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-top-right-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-bottom-right-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-bottom-left-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
}

.dev-phone--dark .dev-phone__body {
  background-color: var(--color-black);
  border: 3px solid #F34747;
}

.dev-phone__media {
  position: relative;
  overflow: hidden;
  border-top-left-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-top-right-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-bottom-left-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-bottom-right-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
}

.dev-phone__media img, .dev-phone__media svg, .dev-phone__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-phone__media::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
  border-radius: inherit;
  pointer-events: none;
}

.dev-phone--dark .dev-phone__media::after {
  display: none;
}

.dev-phone__notch {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 50%;
  right: 25%;
  height: 4%;
  border-radius: 0 0 0.85em 0.85em;
  background-color: var(--color-bg);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-width: 0;
}

.dev-phone--dark .dev-phone__notch {
  background-color: var(--color-black);
  border-width: 0;
}

.dev-phone--landscape .dev-phone__body {
  padding: 1.75%;
  border-top-left-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-top-right-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-bottom-right-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-bottom-left-radius: calc(var(--dev-phone-radius)*var(--dev-phone-img-ratio)) var(--dev-phone-radius);
}

.dev-phone--landscape .dev-phone__media {
  border-top-left-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-top-right-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-bottom-left-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-bottom-right-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
}

.dev-phone--landscape .dev-phone__notch {
  right: 0;
  height: 56%;
  top: 22%;
  width: 4%;
  border-radius: 0.85em 0 0 0.85em;
  border-right-width: 0px;
  border-top-width: 1px;
}

.dev-phone--front-light .dev-phone__body {
  box-shadow: var(--phone-inner-shadow), var(--shadow-lg);
}

.dev-phone--top-light .dev-phone__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}

.dev-phone--top-light .dev-phone__shadow::before, .dev-phone--top-light .dev-phone__shadow::after {
  content: '';
  position: absolute;
  border-radius: inherit;
}

.dev-phone--top-light .dev-phone__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}

.dev-phone--top-light .dev-phone__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* #region (Tablet) */
.dev-tablet, .dev-tablet__body {
  position: relative;
  width: 100%;
}

.dev-tablet {
  z-index: 1;
}

.dev-tablet__body {
  background-color: var(--color-bg);
  --tablet-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035), inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--tablet-inner-shadow);
  padding: 3%;
  z-index: 2;
  border-top-left-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-top-right-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-bottom-right-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-bottom-left-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
}

.dev-tablet__media {
  position: relative;
  overflow: hidden;
  border-top-left-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-top-right-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-bottom-left-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
}

.dev-tablet__media img, .dev-tablet__media svg, .dev-tablet__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-tablet__media::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
  border-radius: inherit;
  pointer-events: none;
}

.dev-tablet--landscape .dev-tablet__body {
  padding: 1.75%;
  border-top-left-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-top-right-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-bottom-left-radius: calc(var(--dev-tablet-radius)*var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
}

.dev-tablet--landscape .dev-tablet__media {
  border-top-left-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-top-right-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-bottom-left-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
}

.dev-tablet--front-light .dev-tablet__body {
  box-shadow: var(--tablet-inner-shadow), var(--shadow-lg);
}

.dev-tablet--top-light .dev-tablet__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}

.dev-tablet--top-light .dev-tablet__shadow::before, .dev-tablet--top-light .dev-tablet__shadow::after {
  content: '';
  position: absolute;
  border-radius: inherit;
}

.dev-tablet--top-light .dev-tablet__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}

.dev-tablet--top-light .dev-tablet__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* #region (Browser) */
.dev-browser,
.dev-browser__body {
  position: relative;
  width: 100%;
}

.dev-browser {
  z-index: 1;
}

.dev-browser__body {
  background: var(--color-bg);
  padding: 2em 1.5% 1.5%;
  --browser-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035), inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--browser-inner-shadow);
  border-top-left-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-top-right-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-bottom-right-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-bottom-left-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  z-index: 2;
}

.dev-browser__media {
  overflow: hidden;
  border-radius: var(--radius-md);
}

.dev-browser__media img, .dev-browser__media svg, .dev-browser__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-browser__dots {
  position: absolute;
  left: calc(1.5% + 0.4em);
  top: calc(1em - 6px);
  display: flex;
}

.dev-browser__dots span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2);
  margin-right: var(--space-xxs);
}

.dev-browser--front-light .dev-browser__body {
  box-shadow: var(--browser-inner-shadow), var(--shadow-lg);
}

.dev-browser--top-light .dev-browser__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}

.dev-browser--top-light .dev-browser__shadow::before, .dev-browser--top-light .dev-browser__shadow::after {
  content: '';
  position: absolute;
  border-radius: inherit;
}

.dev-browser--top-light .dev-browser__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}

.dev-browser--top-light .dev-browser__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* #endregion */
/* #region (Laptop) */
.dev-laptop {
  position: relative;
  z-index: 1;
  width: 100%;
}

.dev-laptop__body {
  width: 85%;
  margin: 0 auto;
  background-color: var(--color-black);
  border: 3px solid var(--color-contrast-low);
  border-bottom-width: 0;
  padding: 3% 1.5% 1.5%;
  border-top-left-radius: var(--dev-laptop-radius) calc(var(--dev-laptop-radius) * var(--dev-laptop-img-ratio));
  border-top-right-radius: var(--dev-laptop-radius) calc(var(--dev-laptop-radius) * var(--dev-laptop-img-ratio));
  z-index: 2;
}

.dev-laptop__media img, .dev-laptop__media svg, .dev-laptop__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-laptop__base {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 3%;
  background: linear-gradient(hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 1.4)), var(--color-contrast-low) 7% 55%, hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.85)) 55%, hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.5)));
  border-bottom-left-radius: 10% 50%;
  border-bottom-right-radius: 10% 50%;
  z-index: 2;
}

.dev-laptop__base::after {
  content: '';
  position: absolute;
  top: 0;
  left: 40%;
  width: 20%;
  height: 35%;
  border-radius: 0 0 1em 1em;
  background-color: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.8));
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
}

.dev-laptop__shadow {
  position: absolute;
  z-index: 1;
  height: 1em;
  width: 94%;
  left: 3%;
  bottom: -0.5em;
  perspective: 400px;
}

.dev-laptop__shadow::before, .dev-laptop__shadow::after {
  content: '';
  position: absolute;
}

.dev-laptop__shadow::before {
  top: 0;
  left: 1%;
  width: 98%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  transform: rotateX(70deg);
  filter: blur(5px);
}

.dev-laptop__shadow::after {
  top: calc(44% - 1px);
  left: 4%;
  width: 92%;
  height: 12%;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  filter: blur(1px);
}

/* #endregion */
/* #region (Desktop) */
.dev-desktop {
  position: relative;
  z-index: 1;
  width: 100%;
}

.dev-desktop__body {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-black);
  border: 3px solid var(--color-contrast-low);
  padding: 4%;
  border-top-left-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-top-right-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-bottom-right-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-bottom-left-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
}

.dev-desktop__media img, .dev-desktop__media svg, .dev-desktop__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-desktop__base {
  position: relative;
  z-index: 1;
  width: 33%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 15%;
  perspective: 180px;
}

.dev-desktop__base-top {
  position: absolute;
  z-index: 3;
  top: -1px;
  left: 15%;
  width: 70%;
  height: 80%;
  clip-path: polygon(3% 0%, 97% 0%, 100% 100%, 0% 100%);
  background: linear-gradient(hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.6)), var(--color-contrast-low));
}

.dev-desktop__base-bottom {
  position: absolute;
  z-index: 2;
  top: calc(80% - 2px);
  left: 15%;
  width: 70%;
  height: 50%;
  border-radius: 0 0 0.25em 0.25em;
  transform-origin: center top;
  transform: rotateX(80deg);
  background-color: var(--color-contrast-low);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, 0.25);
}

.dev-desktop__shadow {
  position: absolute;
  z-index: 1;
  top: calc(80% - 2px);
  left: 17%;
  width: 66%;
  height: 50%;
  border-radius: 0 0 0.25em 0.25em;
  transform-origin: center top;
  transform: rotateX(80deg);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4), 0 4px 4px rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* -------------------------------- 

File#: _2_device-group
Title: Device Group
Descr: A group of devices
Usage: codyhouse.co/license

-------------------------------- */
.device-group-1 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
}

.device-group-1 > * {
  position: relative;
  grid-row: 1 / -1;
}

.device-group-1 > *:nth-child(1), .device-group-1 > *:nth-child(3) {
  z-index: 1;
}

.device-group-1 > *:nth-child(1) {
  grid-column: 1 / 5;
}

.device-group-1 > *:nth-child(2) {
  z-index: 2;
  grid-column: 4 / 8;
}

.device-group-1 > *:nth-child(3) {
  grid-column: 7 / 11;
}

.device-group-1 .dev-phone-3d-wrapper {
  perspective: 1000px;
}

.device-group-1 .dev-phone-3d-wrapper:first-child .dev-phone,
.device-group-1 .dev-phone-3d-wrapper:last-child .dev-phone {
  transition: transform .5s;
}

.device-group-1 .dev-phone-3d-wrapper:first-child .dev-phone {
  transform: rotateY(30deg);
  transform-origin: left center;
}

.device-group-1 .dev-phone-3d-wrapper:last-child .dev-phone {
  transform: rotateY(-30deg);
  transform-origin: right center;
}

.device-group-1:hover .dev-phone-3d-wrapper:first-child .dev-phone {
  transform: rotateY(20deg);
}

.device-group-1:hover .dev-phone-3d-wrapper:last-child .dev-phone {
  transform: rotateY(-20deg);
}

.device-group-2 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 2.5%;
}

.device-group-2 > * {
  position: relative;
  grid-row: 1 / -1;
}

.device-group-2 .dev-phone {
  z-index: 2;
  grid-column: 1 / 4;
  bottom: -5%;
}

.device-group-2 .dev-laptop {
  z-index: 1;
  grid-column: 2 / 13;
}

.device-group-3 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 3.75%;
}

.device-group-3 > * {
  position: relative;
  grid-row: 1 / -1;
}

.device-group-3 .dev-phone {
  z-index: 2;
  grid-column: 1 / 4;
  bottom: -5%;
}

.device-group-3 .dev-desktop {
  z-index: 1;
  grid-column: 2 / 13;
}

.device-group-4 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 2.75%;
}

.device-group-4 > * {
  position: relative;
  grid-row: 1 / -1;
}

.device-group-4 .dev-phone {
  z-index: 2;
  grid-column: 1 / 3;
  bottom: -5%;
}

.device-group-4 .dev-desktop {
  z-index: 1;
  grid-column: 2 / 10;
}

.device-group-4 .dev-laptop {
  z-index: 2;
  bottom: -5%;
  grid-column: 6 / 13;
}

/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card-v8 {
  display: block;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-bg);
  text-decoration: none;
  transition: 0.2s;
}

.card-v8 img {
  display: block;
  width: 100%;
}

.card-v8:hover {
  box-shadow: var(--shadow-md);
}

.card-v8:hover .card-v8__title {
  background-size: 100% 100%;
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2) 50%);
  background-size: 0% 100%;
}

/* -------------------------------- 

File#: _1_card-v3
Title: Card v3
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */
.card-v3 {
  background-color: var(--color-contrast-lower);
}

.card-v3__link {
  text-decoration: none;
  display: block;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;
}

.card-v3__img img {
  display: block;
  width: 100%;
}

.card-v3__content {
  padding: var(--space-sm);
}

.card-v3__label {
  font-size: var(--text-sm);
  margin-bottom: var(--space-xs);
  color: var(--color-contrast-medium);
}

.card-v3__footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  margin-top: auto;
}

.card-v3__footer span {
  display: inline-block;
  font-size: var(--text-sm);
  will-change: transform;
  transform: translateY(20%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.card-v3__footer .icon {
  display: block;
  color: inherit;
}

.card-v3__footer::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 0.3s var(--ease-in-out);
}

.card-v3:hover .card-v3__footer span {
  transform: translateY(0);
  opacity: 1;
}

.card-v3:hover .card-v3__footer::before {
  transform: scaleX(1);
}

.grid__card {
  position: relative;
}

.grid__card.grid__card--left {
  margin-left: 100px;
}

@media screen and (max-width: 768px) {
  .grid__card.grid__card--left {
    margin-left: var(--offset);
  }
}

.grid__card figure, .grid__card h3 {
  position: relative;
  z-index: 2;
}

.grid__card .img__container {
  position: absolute;
  width: 514px;
  height: 314px;
  left: -60px;
  top: -100px;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.1);
}

@media screen and (max-width: 768px) {
  .grid__card .img__container {
    left: 0;
    right: 0;
    width: 100%;
    height: 240px;
    top: 0;
  }
}

/* -------------------------------- 

File#: _1_cta-banner
Title: Call to Action Banner
Descr: A banner containing brief info about a subject, plus links/buttons
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_alert
Title: Alert
Descr: Feedback message
Usage: codyhouse.co/license

-------------------------------- */
.alert {
  padding: var(--space-xs) var(--space-sm);
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  border-radius: var(--radius-md);
  color: var(--color-contrast-higher);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.alert__link {
  color: inherit;
  text-decoration: underline;
}

.alert__close-btn {
  display: inline-block;
  flex-shrink: 0;
  margin-left: var(--space-sm);
}

.alert__close-btn .icon {
  display: block;
}

.alert--success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.2);
}

.alert--error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.alert--warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.2);
}

.alert--is-visible {
  position: static;
  clip: auto;
  clip-path: none;
}

/* -------------------------------- 

File#: _1_breadcrumbs
Title: Breadcrumbss
Descr: List of links to help the user move within website structure
Usage: codyhouse.co/license

-------------------------------- */
.breadcrumbs__item {
  display: inline-block;
  display: inline-flex;
  align-items: center;
}

/* -------------------------------- 

File#: _1_reveal-effects
Title: Reveal Effects
Descr: A collection of reveal effects targeting specific elements as they enter the viewport
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --reveal-fx-duration: 0.6s;
  --reveal-fx-timing-function: var(--ease-out);
}

.js .reveal-fx {
  opacity: 0;
  transition: opacity, transform var(--reveal-fx-timing-function);
  transition-duration: var(--reveal-fx-duration);
}

.js .reveal-fx::before {
  display: none;
  content: 'reveal-fx';
}

.js .reveal-fx--translate, .js .reveal-fx--translate-up {
  transform: translateY(50px);
}

.js .reveal-fx--translate-right {
  transform: translateX(-50px);
}

.js .reveal-fx--translate-left {
  transform: translateX(50px);
}

.js .reveal-fx--translate-down {
  transform: translateY(-50px);
}

.js .reveal-fx--scale {
  transform: scale(0.8);
}

.js .reveal-fx--scale-up {
  transform: translateY(50px) scale(0.8);
}

.js .reveal-fx--scale-right {
  transform: translateX(-50px) scale(0.8);
}

.js .reveal-fx--scale-left {
  transform: translateX(50px) scale(0.8);
}

.js .reveal-fx--scale-down {
  transform: translateY(-50px) scale(0.8);
}

.js .reveal-fx--rotate, .js .reveal-fx--rotate-down, .js .reveal-fx--rotate-right, .js .reveal-fx--rotate-left, .js .reveal-fx--rotate-up {
  perspective: 1000px;
}

.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > *, .js .reveal-fx--rotate-right > *, .js .reveal-fx--rotate-left > *, .js .reveal-fx--rotate-up > * {
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  backface-visibility: hidden;
}

.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > * {
  transform-origin: top;
  transform: rotateX(-45deg);
}

.js .reveal-fx--rotate-right > * {
  transform-origin: left center;
  transform: rotateY(45deg);
}

.js .reveal-fx--rotate-left > * {
  transform-origin: right center;
  transform: rotateY(-45deg);
}

.js .reveal-fx--rotate-up > * {
  transform-origin: bottom;
  transform: rotateX(45deg);
}

.js .reveal-fx--text-mask {
  overflow: hidden;
}

.js .reveal-fx--text-mask > * {
  display: inline-block;
  transform: translateY(100%);
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  will-change: transform;
}

.js [class*="reveal-fx--translate"], .js [class*="reveal-fx--scale"] {
  will-change: opacity, transform;
}

.js .reveal-fx--text-mask > *, .js [class*="reveal-fx--rotate"] > * {
  will-change: transform;
}

.js .reveal-fx--clip-x > *,
.js .reveal-fx--clip-y > * {
  transition: opacity, clip-path var(--ease-out);
  transition-duration: var(--reveal-fx-duration);
}

.js .reveal-fx--clip-x > * {
  clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
}

.js .reveal-fx--clip-y > * {
  clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}

.js .reveal-fx--is-visible {
  opacity: 1;
}

.js .reveal-fx--is-visible[class*="reveal-fx--translate"], .js .reveal-fx--is-visible[class*="reveal-fx--scale"],
.js .reveal-fx--is-visible[class*="reveal-fx--rotate"] > *,
.js .reveal-fx--is-visible.reveal-fx--text-mask > * {
  transform: translate(0);
}

.js .reveal-fx--is-visible.reveal-fx--clip-x > *, .js .reveal-fx--is-visible.reveal-fx--clip-y > * {
  opacity: 1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */
@media (min-width: 64rem) {
  .main-footer__content {
    display: flex;
    justify-content: space-between;
  }
}

.main-footer__logo {
  flex-shrink: 0;
  margin-bottom: var(--space-md);
}

.main-footer__logo a, .main-footer__logo svg, .main-footer__logo img {
  display: block;
}

@media (min-width: 64rem) {
  .main-footer__nav {
    width: calc(100% - 420px);
  }
}

@media (min-width: 80rem) {
  .main-footer__nav {
    width: auto;
  }
}

.main-footer__nav-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer__nav-item {
  display: grid;
  grid-gap: var(--space-xxs);
  margin-bottom: var(--space-md);
  width: 100%;
  padding-right: var(--space-sm);
}

@media (min-width: 32rem) {
  .main-footer__nav-item {
    width: 50%;
  }
}

@media (min-width: 80rem) {
  .main-footer__nav-item {
    grid-gap: var(--space-xs);
    width: 240px;
  }
}

.main-footer__nav-item a {
  color: var(--color-contrast-medium);
}

.main-footer__nav-item a:hover {
  color: var(--color-contrast-high);
}

@media (min-width: 80rem) {
  .main-footer__nav-item a {
    font-size: 0.9em;
  }
}

.main-footer__colophon {
  border-top: 1px solid var(--color-contrast-low);
  padding-top: var(--space-xxxs);
  margin-top: var(--space-md);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.main-footer__colophon-nav {
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
  margin-right: var(--space-sm);
}

.main-footer__colophon-nav > * {
  margin-right: var(--space-xxs);
}

.main-footer__colophon-nav a {
  color: inherit;
}

.main-footer__colophon-nav a:hover {
  color: var(--color-contrast-high);
}

@media (min-width: 64rem) {
  .main-footer__colophon-nav {
    font-size: var(--text-xs);
  }
}

.main-footer__socials {
  display: flex;
  margin: var(--space-xxs) 0;
}

.main-footer__socials a {
  text-decoration: none;
  display: inline-block;
  margin-left: var(--space-xxs);
  color: var(--color-contrast-medium);
}

.main-footer__socials a:hover {
  color: var(--color-contrast-high);
}

.main-footer__socials a svg {
  display: block;
  width: 1em;
  height: 1em;
  color: inherit;
}
