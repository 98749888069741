:root, [data-theme="default"] {
  // main
  @include defineColorHSL(--color-primary-darker, 26, 33%, 0%);
  @include defineColorHSL(--color-primary-dark, 26, 33%, 9%);
  @include defineColorHSL(--color-primary, 26, 33%, 19%);
  @include defineColorHSL(--color-primary-light, 26, 33%, 29%);
  @include defineColorHSL(--color-primary-lighter, 26, 33%, 39%);

  @include defineColorHSL(--color-accent-darker, 31, 30%, 65%);
  @include defineColorHSL(--color-accent-dark, 31, 30%, 75%);
  @include defineColorHSL(--color-accent, 31, 30%, 85%);
  @include defineColorHSL(--color-accent-light, 31, 30%, 93%);
  @include defineColorHSL(--color-accent-lighter, 31, 30%, 100%);

  @include defineColorHSL(--color-secondary-darker, 40, 20%, 77%);
  @include defineColorHSL(--color-secondary-dark, 40, 20%, 87%);
  @include defineColorHSL(--color-secondary, 40, 20%, 97%);
  @include defineColorHSL(--color-secondary-light, 40, 20%, 99%);
  @include defineColorHSL(--color-secondary-lighter, 40, 20%, 100%);

  @include defineColorHSL(--color-black, 220, 4%, 15%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 41%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 51%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 81%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 36%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 46%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 66%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 76%);

  @include defineColorHSL(--color-error-darker, 349, 75%, 31%);
  @include defineColorHSL(--color-error-dark, 349, 75%, 41%);
  @include defineColorHSL(--color-error, 349, 75%, 51%);
  @include defineColorHSL(--color-error-light, 349, 75%, 61%);
  @include defineColorHSL(--color-error-lighter, 349, 75%, 71%);

  // color contrasts
  @include defineColorHSL(--color-bg, 36, 24%, 96%);
  @include defineColorHSL(--color-contrast-lower, 36, 11%, 91%);
  @include defineColorHSL(--color-contrast-low, 40, 3%, 80%);
  @include defineColorHSL(--color-contrast-medium, 270, 1%, 46%);
  @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
}

@supports(--css: variables) {
  [data-theme="blue"] {
    // main
    @include defineColorHSL(--color-primary-darker, 31, 30%, 65%);
    @include defineColorHSL(--color-primary-dark, 31, 30%, 75%);
    @include defineColorHSL(--color-primary, 31, 30%, 85%);
    @include defineColorHSL(--color-primary-light, 31, 30%, 93%);
    @include defineColorHSL(--color-primary-lighter, 31, 30%, 100%);

    @include defineColorHSL(--color-accent-darker, 0, 0%, 33%);
    @include defineColorHSL(--color-accent-dark, 0, 0%, 43%);
    @include defineColorHSL(--color-accent, 0, 0%, 53%);
    @include defineColorHSL(--color-accent-light, 0, 0%, 63%);
    @include defineColorHSL(--color-accent-lighter, 0, 0%, 73%);

    @include defineColorHSL(--color-secondary-darker, 260, 100%, 0%);
    @include defineColorHSL(--color-secondary-dark, 260, 100%, 6%);
    @include defineColorHSL(--color-secondary, 260, 100%, 13%);
    @include defineColorHSL(--color-secondary-light, 260, 100%, 23%);
    @include defineColorHSL(--color-secondary-lighter, 260, 100%, 33%);

    @include defineColorHSL(--color-black, 220, 4%, 15%);
    @include defineColorHSL(--color-white, 0, 0%, 100%);

    // feedback
    @include defineColorHSL(--color-warning-darker, 46, 100%, 41%);
    @include defineColorHSL(--color-warning-dark, 46, 100%, 51%);
    @include defineColorHSL(--color-warning, 46, 100%, 61%);
    @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
    @include defineColorHSL(--color-warning-lighter, 46, 100%, 81%);

    @include defineColorHSL(--color-success-darker, 94, 48%, 36%);
    @include defineColorHSL(--color-success-dark, 94, 48%, 46%);
    @include defineColorHSL(--color-success, 94, 48%, 56%);
    @include defineColorHSL(--color-success-light, 94, 48%, 66%);
    @include defineColorHSL(--color-success-lighter, 94, 48%, 76%);

    @include defineColorHSL(--color-error-darker, 349, 75%, 31%);
    @include defineColorHSL(--color-error-dark, 349, 75%, 41%);
    @include defineColorHSL(--color-error, 349, 75%, 51%);
    @include defineColorHSL(--color-error-light, 349, 75%, 61%);
    @include defineColorHSL(--color-error-lighter, 349, 75%, 71%);

    // color contrasts
    @include defineColorHSL(--color-bg, 26, 33%, 19%);
    @include defineColorHSL(--color-contrast-lower, 25, 27%, 22%);
    @include defineColorHSL(--color-contrast-low, 26, 19%, 31%);
    @include defineColorHSL(--color-contrast-medium, 26, 10%, 59%);
    @include defineColorHSL(--color-contrast-high, 33, 13%, 86%);
    @include defineColorHSL(--color-contrast-higher, 36, 24%, 96%);
  }
}

@supports(--css: variables) {
  [data-theme="grey"] {
    // main
    @include defineColorHSL(--color-primary-darker, 26, 33%, 0%);
    @include defineColorHSL(--color-primary-dark, 26, 33%, 9%);
    @include defineColorHSL(--color-primary, 26, 33%, 19%);
    @include defineColorHSL(--color-primary-light, 26, 33%, 29%);
    @include defineColorHSL(--color-primary-lighter, 26, 33%, 39%);

    @include defineColorHSL(--color-accent-darker, 24, 11%, 62%);
    @include defineColorHSL(--color-accent-dark, 24, 11%, 72%);
    @include defineColorHSL(--color-accent, 24, 11%, 82%);
    @include defineColorHSL(--color-accent-light, 24, 11%, 91%);
    @include defineColorHSL(--color-accent-lighter, 24, 11%, 100%);

    @include defineColorHSL(--color-secondary-darker, 202, 22%, 29%);
    @include defineColorHSL(--color-secondary-dark, 202, 22%, 39%);
    @include defineColorHSL(--color-secondary, 202, 22%, 49%);
    @include defineColorHSL(--color-secondary-light, 202, 22%, 59%);
    @include defineColorHSL(--color-secondary-lighter, 202, 22%, 69%);

    @include defineColorHSL(--color-black, 220, 4%, 15%);
    @include defineColorHSL(--color-white, 0, 0%, 100%);

    // feedback
    @include defineColorHSL(--color-warning-darker, 46, 100%, 41%);
    @include defineColorHSL(--color-warning-dark, 46, 100%, 51%);
    @include defineColorHSL(--color-warning, 46, 100%, 61%);
    @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
    @include defineColorHSL(--color-warning-lighter, 46, 100%, 81%);

    @include defineColorHSL(--color-success-darker, 94, 48%, 36%);
    @include defineColorHSL(--color-success-dark, 94, 48%, 46%);
    @include defineColorHSL(--color-success, 94, 48%, 56%);
    @include defineColorHSL(--color-success-light, 94, 48%, 66%);
    @include defineColorHSL(--color-success-lighter, 94, 48%, 76%);

    @include defineColorHSL(--color-error-darker, 349, 75%, 31%);
    @include defineColorHSL(--color-error-dark, 349, 75%, 41%);
    @include defineColorHSL(--color-error, 349, 75%, 51%);
    @include defineColorHSL(--color-error-light, 349, 75%, 61%);
    @include defineColorHSL(--color-error-lighter, 349, 75%, 71%);

    // color contrasts
    @include defineColorHSL(--color-bg, 31, 30%, 85%);
    @include defineColorHSL(--color-contrast-lower, 30, 22%, 81%);
    @include defineColorHSL(--color-contrast-low, 28, 12%, 71%);
    @include defineColorHSL(--color-contrast-medium, 20, 3%, 42%);
    @include defineColorHSL(--color-contrast-high, 270, 4%, 18%);
    @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
  }
}

// background color
.bg-secondary-darker { background-color: alpha(var(--color-secondary-darker), var(--bg-o, 1)); }
.bg-secondary-dark { background-color: alpha(var(--color-secondary-dark), var(--bg-o, 1)); }
.bg-secondary { background-color: alpha(var(--color-secondary), var(--bg-o, 1)); }
.bg-secondary-light { background-color: alpha(var(--color-secondary-light), var(--bg-o, 1)); }
.bg-secondary-lighter { background-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1)); }

// border color
.border-secondary-darker { border-color: alpha(var(--color-secondary-darker), var(--bg-o, 1)); }
.border-secondary-dark { border-color: alpha(var(--color-secondary-dark), var(--bg-o, 1)); }
.border-secondary { border-color: alpha(var(--color-secondary), var(--bg-o, 1)); }
.border-secondary-light { border-color: alpha(var(--color-secondary-light), var(--bg-o, 1)); }
.border-secondary-lighter { border-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1)); }