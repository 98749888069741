:root {
  // font family
  --font-primary: 'DM Serif Display', serif;
  --font-secondary: 'DM Sans', sans-serif;

  // font size
  --text-base-size: 1.4em; // body font-size
  --text-scale-ratio: 1.2; // multiplier used to generate the type scale values 👇
  
  // line-height
  --body-line-height: 1.4;
  --heading-line-height: 1.4;
  
  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;

  // unit - don't modify unless you want to change the typography unit (e.g., from Em to Rem units)
  --text-unit: 1em; // if not Em units → --text-unit: var(--text-base-size);
}

:root, * {
  // type scale
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@supports(--css: variables) {
  @include breakpoint(md) {
    :root {
      --text-base-size: 1.1em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-secondary);
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: var(--font-primary);
  font-weight: 700;
}

// font family
.font-primary { font-family: var(--font-primary); }
.font-secondary { font-family: var(--font-secondary); }

// color
.color-secondary-darker { color: alpha(var(--color-secondary-darker), var(--color-o, 1)); }
.color-secondary-dark { color: alpha(var(--color-secondary-dark), var(--color-o, 1)); }
.color-secondary { color: alpha(var(--color-secondary), var(--color-o, 1)); }
.color-secondary-light { color: alpha(var(--color-secondary-light), var(--color-o, 1)); }
.color-secondary-lighter { color: alpha(var(--color-secondary-lighter), var(--color-o, 1)); }