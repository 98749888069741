/* -------------------------------- 

File#: _1_section-divider
Title: Section divider
Descr: A collection of SVG section dividers.
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --section-divider-width: 1920; // px
  --section-divider-height: 60; // px
  --section-divider-ratio: calc(
    100% * var(--section-divider-height) / var(--section-divider-width)
  );
}

[class*="has-section-divider"] {
  position: relative;
}

.has-section-dividers {
  // section with dividers on both sides
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);

  .section-divider:first-child {
    bottom: auto;
    top: -1px;
    transform: scaleY(-1);
  }
}

.has-section-divider-top {
  // section with bottom divider
  padding-top: var(--section-divider-ratio);

  .section-divider {
    bottom: auto;
    top: -1px;
    transform: scaleY(-1);
  }
}

.has-section-divider-bottom {
  // section with top divider
  padding-bottom: var(--section-divider-ratio);
}

.has-bg-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .bg-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(var(--color-primary-light), 0.2);
  }
}

.section-divider {
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: auto;
  width: 100%;
}

.hero {
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  min-height: 380px;
  // if bg = img
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include breakpoint(md) {
    min-height: 540px;
  }
}

.hero__content {
}

.hero__label {
  color: alpha(var(--color-contrast-high), 0.6);
  font-size: var(--text-sm);
}

// --overlay-layer
.hero--overlay-layer {
  position: relative;

  .hero__content {
    position: relative;
    z-index: 2;
  }

  &::after {
    // add a layer in between the img/video background and the content
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: alpha(var(--color-bg), 0.65);
    z-index: 1;
  }
}

// --full-screen
.hero--full-screen {
  height: 100vh;
}

.cta__block {
  background: var(--color-primary);
  height: 160px;
  width: 44%;
  position: relative;
  top: -66px;
  left: 91px;
  cursor: pointer;
  padding: 60px 40px;
  text: center;
  &:hover {
    text-decoration: none !important;
    background: var(--color-primary-dark);
  }

  h3 {
    display: flex;
    align-items: center;
  }
}

.cta__block-arrow {
  margin-left: 32px;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  background: var(--color-primary-dark);
  display: flex;
  align-items: center;
  justify-content: center;


  svg {

    #icon {
      fill: #fff;
    }
    
  }

}

.img--styled {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    right: -2rem;
    bottom: -2rem;
    border-radius: 2rem 2rem 0 0;
    background: var(--color-accent-light);
    width: 100%;
   
    height: calc(100% - 2rem);
    content: "";
  //  z-index: -1;

  @media screen and (max-width: 768px) {
    right: -1rem;
    bottom: -1rem;
    border-radius: 1rem 1rem 0 0;
  }
  }

  img {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 2rem 2rem 0 5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media screen and (max-width: 768px) {

    border-radius: 0.6rem 0.6rem 0 2rem;
  }
  }
}
